import { useContext, useEffect, useState } from 'react'

import { Context as CreatorContext } from '../../../../context/CreatorContext'
import { Context as CashLogsContext } from '../../../../context/CashLogsContext'

const CalculateCommissionAccount = () => {
  const [transactions, setTransactions] = useState([])

  const {
    state: { siteTransactionHistory },
  } = useContext(CreatorContext)

  const { setMoneyIn, setMoneyOut } = useContext(CashLogsContext)

  useEffect(() => {
    if (siteTransactionHistory) {
      let array = siteTransactionHistory.filter((siteTrans) => {
        return siteTrans.commissionPaid === false
      })
      setTransactions(array)
    }
  }, [siteTransactionHistory])

  useEffect(() => {
    if (transactions.length > 0) {
      let typeAddCredit = []
      let typeCashOut = []
      typeAddCredit = transactions.filter((reqLog) => {
        return reqLog.type === 'addCredit'
      })
      typeCashOut = transactions.filter((reqLog) => {
        return reqLog.type === 'cashOut'
      })
      if (typeAddCredit.length > 0) {
        let valuesIn = []
        let sumIn = 0
        valuesIn = typeAddCredit.map((addCred) => {
          return addCred.value
        })
        valuesIn.forEach((value) => {
          sumIn += value
        })
        setMoneyIn(sumIn)
      }
      if (typeCashOut.length > 0) {
        let valuesOut = []
        let sumOut = 0
        valuesOut = typeCashOut.map((cashOut) => {
          return cashOut.value
        })
        valuesOut.forEach((value) => {
          sumOut += value
        })
        setMoneyOut(sumOut)
      }
    }
  }, [transactions])

  return null
}

export default CalculateCommissionAccount
