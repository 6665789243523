import React, { useState, useContext, useEffect } from 'react'
import { Context as AuthContext } from '../../../context/AuthContext'
import './login.css'

const Login = () => {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')

  const {
    state: { error },
    login,
    clearError,
    clearSuccess,
    setPanic,
  } = useContext(AuthContext)

  useEffect(() => {
    clearSuccess()
    setPanic(false)
  }, [])

  const renderContent = () => {
    return (
      <div className="loginBed">
        <div className="loginContainer">
          <div className="loginFormBed">
            <div className="loginFormHeader">login</div>
            <div className="loginFormBody">
              <input
                className="loginInput"
                placeholder="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                onFocus={() => clearError()}
              />
              {!error ? null : <div className="loginError">{error}</div>}
              <input
                className="loginInput"
                placeholder="password"
                value={password}
                type="password"
                onChange={(e) => setPassword(e.target.value)}
                onFocus={() => clearError()}
              />
            </div>
            <div className="loginFormFooter">
              <div
                className="loginButton"
                onClick={() => login({ email, password })}
              >
                submit
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  return renderContent()
}

export default Login
