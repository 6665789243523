export const fruitsArray = [
  { id: 1, symbol: '🍈' },
  { id: 2, symbol: '🍋' },
  { id: 3, symbol: '🍊' },
  { id: 4, symbol: '🥑' },
  { id: 5, symbol: '🍉' },
  { id: 6, symbol: '🍌' },
  { id: 7, symbol: '🍇' },
  { id: 8, symbol: '🍒' },
  { id: 9, symbol: '🍓' },
  { id: 10, symbol: '🍀' },
  { id: 11, symbol: '🔔' },
]
