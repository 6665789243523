import React, { useContext, useEffect, useState } from 'react'
import _ from 'lodash'
import moment from 'moment'

import LoaderSmall from '../../common/loader/LoaderSmall'
import { Context as CreatorContext } from '../../../context/CreatorContext'
import './transactionHistory.css'

const TransactionHistory = () => {
  const [showContent, setShowContent] = useState(false)
  const [transactionHistoryTenSorted, setTransactionHistoryTenSorted] =
    useState([])
  const [showAllCollection, setShowAllCollection] = useState(false)

  const {
    state: { loading, clientSelected, clientTransactionHistory },
    fetchClientTransactionHistory,
  } = useContext(CreatorContext)

  useEffect(() => {
    if (clientSelected) {
      fetchClientTransactionHistory({ id: clientSelected._id })
    }
  }, [clientSelected])

  useEffect(() => {
    if (clientTransactionHistory) {
      let array = _.orderBy(
        clientTransactionHistory,
        [(item) => item.created],
        ['desc']
      )
      let arrayTen = array.splice(0, 10)
      setTransactionHistoryTenSorted(arrayTen)
    }
  }, [clientTransactionHistory])

  const renderList = () => {
    if (!clientTransactionHistory || clientTransactionHistory.length < 1)
      return null
    let arraySorted = _.orderBy(
      clientTransactionHistory,
      [(item) => item.created],
      ['desc']
    )
    let array = showAllCollection ? arraySorted : transactionHistoryTenSorted
    return array.map((transaction) => {
      return (
        <div className="clientTransactionCell" key={transaction._id}>
          <div className="clientTransaction">
            <div className="clientTransactionCellLabel">type</div>
            <div className="clientTransactionCellText">{transaction.type}</div>
          </div>
          <div className="clientTransaction">
            <div className="clientTransactionCellLabel">value</div>
            <div className="clientTransactionCellText">
              {transaction.value
                .toString()
                .replace(/.(?=(...........)*..$)/g, '$&,')}
            </div>
          </div>
          <div className="clientTransaction">
            <div className="clientTransactionCellLabel">old balance</div>
            <div className="clientTransactionCellText">
              {transaction.clientOldBalance
                .toString()
                .replace(/.(?=(...........)*..$)/g, '$&,')}
            </div>
          </div>
          <div className="clientTransaction">
            <div className="clientTransactionCellLabel">new balance</div>
            <div className="clientTransactionCellText">
              {transaction.clientNewBalance
                .toString()
                .replace(/.(?=(...........)*..$)/g, '$&,')}
            </div>
          </div>
          <div className="clientTransaction">
            <div className="clientTransactionCellLabel">created</div>
            <div className="clientTransactionCellText">
              {moment(transaction.created).format('MMMM Do YYYY, h:mm:ss a')}
            </div>
          </div>
        </div>
      )
    })
  }

  const renderContent = () => {
    if (loading) return <LoaderSmall />
    return (
      <>
        <div className="transactionHistoryButtonBed">
          <div
            className="transactionHistoryButton"
            onClick={() => setShowContent(!showContent)}
          >
            {!showContent ? 'View' : 'Hide'} transactions
          </div>
        </div>
        {!showContent ? null : (
          <>
            <div className="clientTransactionHeadingBed">
              <div className="clientTransactionHeading">
                Transaction history
              </div>
              {!showAllCollection ? (
                <div
                  className="clientTransactionShowAllButton"
                  onClick={() => setShowAllCollection(true)}
                >
                  Show all
                </div>
              ) : (
                <div
                  className="clientTransactionShowAllButton"
                  onClick={() => setShowAllCollection(false)}
                >
                  Show 10
                </div>
              )}
            </div>
            <div className="clientTransactionBed">
              <div className="clientTransactionContainer">{renderList()}</div>
            </div>
          </>
        )}
      </>
    )
  }

  return renderContent()
}

export default TransactionHistory
