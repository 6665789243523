import _ from 'lodash'
import React, { useState, useContext, useEffect } from 'react'
import moment from 'moment'

import SiteBaitTriggerInfo from './SiteBaitTriggerInfo'
import { Context as BaitContext } from '../../../../context/BaitContext'
import { Context as CreatorContext } from '../../../../context/CreatorContext'
import { Context as SocketContext } from '../../../../context/SocketContext'
import './siteBait.css'

const SiteBait = () => {
  const [startValue, setStartValue] = useState(0)

  const {
    state: { error, loading, bait },
    clearError,
    createSiteBait,
    fetchSiteBaitList,
  } = useContext(BaitContext)

  const {
    state: { site },
  } = useContext(CreatorContext)

  const { setSocketDataFromCreator } = useContext(SocketContext)

  useEffect(() => {
    fetchSiteBaitList({ siteNumber: site.siteNumber })
  }, [])

  const renderBaitForm = () => {
    return (
      <div className="siteBaitBed">
        <div className="siteBaitHeader">Site bait</div>
        <div className="siteBaitFieldsBed">
          <div>
            <div className="baitValueInputLabel">Bait value</div>
            <input
              className="baitValueInput"
              placeholder="bait value"
              value={startValue}
              onChange={(e) => setStartValue(e.target.value)}
              onFocus={() => clearError()}
            />
            <div className="baitSubmitButtonBed">
              <div
                className="baitSubmitButton"
                onClick={() => {
                  createSiteBait({
                    siteBait: true,
                    siteBaitStartValue: startValue,
                    siteNumber: site.siteNumber,
                  })
                  setStartValue(0)
                }}
              >
                submit
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  const baitList = () => {
    if (!bait || bait.length < 1) return null
    let array = _.orderBy(bait, [(item) => item.created], ['desc'])
    let arrayTen = array.splice(0, 10)
    return arrayTen.map((arr) => {
      let formattedStartValue = (arr.siteBaitStartValue / 100).toFixed(2)
      let formattedBalance = (arr.siteBaitValue / 100).toFixed(2)
      return (
        <div className="baitListItemBed" key={arr._id}>
          <div>
            Date: {moment(arr.created).format('MMMM Do YYYY, h:mm:ss a')}
          </div>
          {formattedBalance > 0 ? (
            <div
              className="pushBaitButton"
              onClick={() => {
                setSocketDataFromCreator({
                  subject: 'triggerClientFetchBait',
                  triggerClientFetchBait: true,
                })
              }}
            >
              ➡
            </div>
          ) : null}
          <div>Start value: {formattedStartValue}</div>
          <div>Balance: {formattedBalance}</div>
        </div>
      )
    })
  }

  const renderBaitList = () => {
    return (
      <div className="baitListBed">
        <div className="baitListHeaderBed">
          <div className="baitListHeader">Bait list</div>
          <div
            className="refreshButton"
            onClick={() => fetchSiteBaitList({ siteNumber: site.siteNumber })}
          >
            ↻
          </div>
        </div>
        {loading ? (
          <div className="siteBaitLoader">Loading...</div>
        ) : (
          baitList()
        )}
      </div>
    )
  }

  const renderContent = () => {
    return (
      <>
        <SiteBaitTriggerInfo />
        {!error ? null : (
          <div className="siteBaitError" onClick={() => clearError()}>
            {error}
          </div>
        )}
        {renderBaitForm()}
        {renderBaitList()}
      </>
    )
  }

  return renderContent()
}

export default SiteBait
