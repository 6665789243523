import ngrokApi from '../api/ngrokApi'
import createDataContext from './createDataContext'

// Reducer
const creatorReducer = (state, action) => {
  switch (action.type) {
    case 'LOADING':
      return { ...state, loading: true }
    case 'ADD_ERROR':
      return { ...state, error: action.payload, loading: false }
    case 'CLEAR_ERROR':
      return { ...state, error: action.payload }
    case 'ADD_SUCCESS':
      return { ...state, success: action.payload, loading: false }
    case 'CLEAR_SUCCESS':
      return { ...state, success: action.payload }
    case 'SET_OPERATOR_SELECTED':
      return { ...state, operator: action.payload }
    case 'FETCH_ALL_OPERATORS':
      return { ...state, operators: action.payload, loading: false }
    case 'FETCH_OPERATORS_SITES':
      return { ...state, sites: action.payload, loading: false }
    case 'FETCH_SITE_CASHIER':
      return { ...state, cashiers: action.payload, loading: false }
    case 'FETCH_SITE_CLIENT':
      return { ...state, clients: action.payload, loading: false }
    case 'SET_SITE_SELECTED':
      return { ...state, site: action.payload }
    case 'SET_CASHIER_SELECTED':
      return { ...state, cashier: action.payload }
    case 'SET_CLIENT_SELECTED':
      return { ...state, client: action.payload }
    case 'FETCH_ALL_CASHIERS':
      return { ...state, cashiers: action.payload, loading: false }
    case 'FETCH_CLIENT_GAME_PLAY':
      return { ...state, clientGamePlay: action.payload, loading: false }
    case 'FETCH_SITE_TRANSACTION_HISTORY':
      return {
        ...state,
        siteTransactionHistory: action.payload,
        loading: false,
      }
    case 'FETCH_CLIENT_TRANSACTION_HISTORY':
      return {
        ...state,
        clientTransactionHistory: action.payload,
        loading: false,
      }
    case 'FETCH_CASHIER_TRANSACTION_HISTORY':
      return {
        ...state,
        cashierTransactionHistory: action.payload,
        loading: false,
      }
    case 'SET_EDIT_FORM_SHOW':
      return { ...state, editFormShow: action.payload }
    case 'SET_EDIT_FORM_PROPS':
      return { ...state, editFormProps: action.payload }
    default:
      return state
  }
}

// Actions
const fetchOperators = (dispatch) => async () => {
  dispatch({ type: 'LOADING' })
  try {
    const response = await ngrokApi.get('creator/all-operators/')
    dispatch({ type: 'FETCH_ALL_OPERATORS', payload: response.data })
    return
  } catch (error) {
    dispatch({
      type: 'ADD_ERROR',
      payload: error,
    })
  }
}

const fetchClientGamePlay = (dispatch) => async (data) => {
  dispatch({ type: 'LOADING' })
  try {
    const response = await ngrokApi.post('/creator/client-game-play/', data)
    dispatch({ type: 'FETCH_CLIENT_GAME_PLAY', payload: response.data })
    return
  } catch (error) {
    dispatch({
      type: 'ADD_ERROR',
      payload: error,
    })
  }
}

const fetchSiteTransactionHistory = (dispatch) => async (siteNumber) => {
  dispatch({ type: 'LOADING' })
  try {
    const response = await ngrokApi.post(
      '/creator/site-transaction-history/',
      siteNumber
    )
    dispatch({
      type: 'FETCH_SITE_TRANSACTION_HISTORY',
      payload: response.data,
    })
    return
  } catch (error) {
    dispatch({
      type: 'ADD_ERROR',
      payload: error,
    })
  }
}

const fetchClientTransactionHistory = (dispatch) => async (data) => {
  dispatch({ type: 'LOADING' })
  try {
    const response = await ngrokApi.post(
      '/creator/client-transaction-history/',
      data
    )
    dispatch({
      type: 'FETCH_CLIENT_TRANSACTION_HISTORY',
      payload: response.data,
    })
    return
  } catch (error) {
    dispatch({
      type: 'ADD_ERROR',
      payload: error,
    })
  }
}

const fetchCashierTransactionHistory = (dispatch) => async (id) => {
  dispatch({ type: 'LOADING' })
  try {
    const response = await ngrokApi.post(
      '/creator/cashier-transaction-history/',
      id
    )
    dispatch({
      type: 'FETCH_CASHIER_TRANSACTION_HISTORY',
      payload: response.data,
    })
    return
  } catch (error) {
    dispatch({
      type: 'ADD_ERROR',
      payload: error,
    })
  }
}

const registerOperator = (dispatch) => async (data) => {
  dispatch({ type: 'LOADING' })
  try {
    const response = await ngrokApi.post('/auth/user/register-operator', data)
    if (response.data.error) {
      dispatch({ type: 'ADD_ERROR', payload: response.data.error })
      return
    }
    if (response.data.success) {
      dispatch({ type: 'ADD_SUCCESS', payload: response.data.success })
      return
    }
  } catch (error) {
    dispatch({
      type: 'ADD_ERROR',
      payload: error,
    })
  }
}

const deleteUser = (dispatch) => async (id) => {
  dispatch({ type: 'LOADING' })
  try {
    const response = await ngrokApi.post('/auth/user/delete-user', id)
    if (response.data.error) {
      dispatch({ type: 'ADD_ERROR', payload: response.data.error })
      return
    }
    if (response.data.success) {
      dispatch({ type: 'ADD_SUCCESS', payload: response.data.success })
      return
    }
  } catch (error) {
    dispatch({
      type: 'ADD_ERROR',
      payload: error,
    })
  }
}

const editUser = (dispatch) => async (data) => {
  dispatch({ type: 'LOADING' })
  try {
    const response = await ngrokApi.patch('/auth/user/edit-user', data)
    if (response.data.error) {
      dispatch({ type: 'ADD_ERROR', payload: response.data.error })
      return
    }
    if (response.data.success) {
      dispatch({ type: 'ADD_SUCCESS', payload: response.data.success })
      return
    }
  } catch (error) {
    dispatch({
      type: 'ADD_ERROR',
      payload: error,
    })
  }
}

const createSite = (dispatch) => async (data) => {
  dispatch({ type: 'LOADING' })
  try {
    const response = await ngrokApi.post('/creator/create-site', data)
    if (response.data.error) {
      dispatch({ type: 'ADD_ERROR', payload: response.data.error })
      return
    }
    if (response.data.success) {
      dispatch({ type: 'ADD_SUCCESS', payload: response.data.success })
      return
    }
  } catch (error) {
    dispatch({
      type: 'ADD_ERROR',
      payload: error,
    })
  }
}

const fetchOperatorsSites = (dispatch) => async (id) => {
  dispatch({ type: 'LOADING' })
  try {
    const response = await ngrokApi.post('/creator/fetch-operators-sites', id)
    dispatch({ type: 'FETCH_OPERATORS_SITES', payload: response.data })
    return
  } catch (error) {
    dispatch({
      type: 'ADD_ERROR',
      payload: error,
    })
  }
}

const deleteSite = (dispatch) => async (id) => {
  dispatch({ type: 'LOADING' })
  try {
    const response = await ngrokApi.post('/creator/delete-site', id)
    if (response.data.error) {
      dispatch({ type: 'ADD_ERROR', payload: response.data.error })
      return
    }
    if (response.data.success) {
      dispatch({ type: 'ADD_SUCCESS', payload: response.data.success })
      return
    }
  } catch (error) {
    dispatch({
      type: 'ADD_ERROR',
      payload: error,
    })
  }
}

const editSite = (dispatch) => async (data) => {
  dispatch({ type: 'LOADING' })
  try {
    const response = await ngrokApi.patch('/creator/edit-site', data)
    if (response.data.error) {
      dispatch({ type: 'ADD_ERROR', payload: response.data.error })
      return
    }
    if (response.data.success) {
      dispatch({ type: 'ADD_SUCCESS', payload: response.data.success })
      return
    }
  } catch (error) {
    dispatch({
      type: 'ADD_ERROR',
      payload: error,
    })
  }
}

const registerCashier = (dispatch) => async (data) => {
  dispatch({ type: 'LOADING' })
  try {
    const response = await ngrokApi.post('/auth/user/register-cashier', data)
    if (response.data.error) {
      dispatch({ type: 'ADD_ERROR', payload: response.data.error })
      return
    }
    if (response.data.success) {
      dispatch({ type: 'ADD_SUCCESS', payload: response.data.success })
      return
    }
  } catch (error) {
    dispatch({
      type: 'ADD_ERROR',
      payload: error,
    })
  }
}

const fetchSiteCashier = (dispatch) => async (siteNumber) => {
  dispatch({ type: 'LOADING' })
  try {
    const response = await ngrokApi.post(
      '/creator/fetch-site-cashier',
      siteNumber
    )
    dispatch({ type: 'FETCH_SITE_CASHIER', payload: response.data })
  } catch (error) {
    dispatch({
      type: 'ADD_ERROR',
      payload: error,
    })
  }
}

const deleteCashier = (dispatch) => async (id) => {
  dispatch({ type: 'LOADING' })
  try {
    const response = await ngrokApi.post('/auth/user/delete-user', id)
    if (response.data.error) {
      dispatch({ type: 'ADD_ERROR', payload: response.data.error })
      return
    }
    if (response.data.success) {
      dispatch({ type: 'ADD_SUCCESS', payload: response.data.success })
      return
    }
  } catch (error) {
    dispatch({
      type: 'ADD_ERROR',
      payload: error,
    })
  }
}

const registerClient = (dispatch) => async (data) => {
  dispatch({ type: 'LOADING' })
  try {
    const response = await ngrokApi.post('/auth/user/register-client', data)
    if (response.data.error) {
      dispatch({ type: 'ADD_ERROR', payload: response.data.error })
      return
    }
    if (response.data.success) {
      dispatch({ type: 'ADD_SUCCESS', payload: response.data.success })
      return
    }
  } catch (error) {
    dispatch({
      type: 'ADD_ERROR',
      payload: error,
    })
  }
}

const fetchSiteClient = (dispatch) => async (siteNumber) => {
  dispatch({ type: 'LOADING' })
  try {
    const response = await ngrokApi.post(
      '/creator/fetch-site-client',
      siteNumber
    )
    dispatch({ type: 'FETCH_SITE_CLIENT', payload: response.data })
  } catch (error) {
    dispatch({
      type: 'ADD_ERROR',
      payload: error,
    })
  }
}

const setSiteSelected = (dispatch) => (data) => {
  dispatch({ type: 'SET_SITE_SELECTED', payload: data })
}

const clearError = (dispatch) => () => {
  dispatch({ type: 'CLEAR_ERROR', payload: null })
}

const clearSuccess = (dispatch) => () => {
  dispatch({ type: 'CLEAR_SUCCESS', payload: null })
}

const setOperatorSelected = (dispatch) => (value) => {
  dispatch({ type: 'SET_OPERATOR_SELECTED', payload: value })
}

const setCashierSelected = (dispatch) => (value) => {
  dispatch({ type: 'SET_CASHIER_SELECTED', payload: value })
}

const setClientSelected = (dispatch) => (value) => {
  dispatch({ type: 'SET_CLIENT_SELECTED', payload: value })
}

const setEditFormShow = (dispatch) => (value) => {
  dispatch({ type: 'SET_EDIT_FORM_SHOW', payload: value })
}
const setEditFormProps = (dispatch) => (data) => {
  dispatch({ type: 'SET_EDIT_FORM_PROPS', payload: data })
}

export const { Provider, Context } = createDataContext(
  creatorReducer,
  {
    fetchOperators,
    fetchClientGamePlay,
    fetchSiteTransactionHistory,
    fetchClientTransactionHistory,
    fetchCashierTransactionHistory,
    registerOperator,
    deleteUser,
    editUser,
    createSite,
    fetchOperatorsSites,
    deleteSite,
    editSite,
    registerCashier,
    deleteCashier,
    fetchSiteCashier,
    registerClient,
    fetchSiteClient,
    setSiteSelected,
    clearError,
    clearSuccess,
    setOperatorSelected,
    setCashierSelected,
    setClientSelected,
    setEditFormShow,
    setEditFormProps,
  },
  {
    loading: null,
    error: null,
    success: null,
    operator: null,
    operators: null,
    site: null,
    sites: null,
    cashier: null,
    cashiers: null,
    client: null,
    clients: null,
    clientGamePlay: null,
    siteTransactionHistory: null,
    clientTransactionHistory: null,
    cashierTransactionHistory: null,
    editFormShow: false,
    editFormProps: null,
  }
)
