import React, { useContext, useEffect, useState } from 'react'
import { AiOutlineArrowLeft } from 'react-icons/ai'

import LoaderSmall from '../../common/loader/LoaderSmall'
import { Context as CreatorContext } from '../../../context/CreatorContext'

const EditSite = () => {
  const [siteNumber, setSiteNumber] = useState('')
  const [province, setProvince] = useState('')
  const [town, setTown] = useState('')
  const [street, setStreet] = useState('')
  const [commissionPercentage, setCommissionPercentage] = useState()

  const {
    state: { loading, error, editFormProps },
    editSite,
    clearError,
    setEditFormShow,
    setEditFormProps,
  } = useContext(CreatorContext)

  useEffect(() => {
    if (editFormProps) {
      if (editFormProps.siteNumber.length > 0)
        setSiteNumber(editFormProps.siteNumber)
      if (editFormProps.province.length > 0) setProvince(editFormProps.province)
      if (editFormProps.town.length > 0) setTown(editFormProps.town)
      if (editFormProps.street.length > 0) setStreet(editFormProps.street)
      if (editFormProps.commissionPercentage > 0)
        setCommissionPercentage(editFormProps.commissionPercentage)
    }
  }, [editFormProps])

  const submit = () => {
    editSite({
      id: editFormProps._id,
      province,
      town,
      street,
      commissionPercentage,
    })
  }

  const renderError = () => {
    if (!error) return null
    return (
      <div className="formErrorBed">
        <div className="formErrorText">{error}</div>
        <div className="formErrorButtonBed">
          <div className="formErrorButton" onClick={() => clearError()}>
            ok
          </div>
        </div>
      </div>
    )
  }

  const form = () => {
    if (loading) return <LoaderSmall />
    if (error) return null
    return (
      <div>
        <div
          className="formCancelButton"
          onClick={() => {
            setEditFormProps(null)
            setEditFormShow(false)
          }}
        >
          <AiOutlineArrowLeft className="formCancelButtonIcon" />
          <div className="formCancelButtonTextBed">
            <div className="formCancelButtonText">cancel</div>
          </div>
        </div>
        <>
          <div className="formInputHeader">Site type</div>
          <div className="formNoneEditableField">{editFormProps.type}</div>
        </>
        <>
          <div className="formInputHeader">Site number</div>
          <div className="formNoneEditableField">{siteNumber}</div>
        </>
        <>
          <div className="formInputHeader">Province</div>
          <input
            className="formInput"
            type="text"
            placeholder="province"
            value={province}
            onChange={(e) => setProvince(e.target.value)}
          />
        </>
        <>
          <div className="formInputHeader">Town</div>
          <input
            className="formInput"
            type="text"
            placeholder="town"
            value={town}
            onChange={(e) => setTown(e.target.value)}
          />
        </>
        <>
          <div className="formInputHeader">Street</div>
          <input
            className="formInput"
            type="text"
            placeholder="street"
            value={street}
            onChange={(e) => setStreet(e.target.value)}
          />
        </>
        <>
          <div className="formInputHeader">Commmission percentage</div>
          <input
            className="formInput"
            type="text"
            placeholder="commission percentage"
            value={commissionPercentage}
            onChange={(e) => setCommissionPercentage(e.target.value)}
          />
        </>
        <div className="formSubmitButtonBed">
          <div
            className="formSubmitButton"
            onClick={() => {
              submit()
              setEditFormShow(false)
            }}
          >
            submit
          </div>
        </div>
      </div>
    )
  }

  const renderContent = () => {
    return (
      <div className="formBed">
        <div className="formHeader">Edit site</div>
        <>{renderError()}</>
        <>{form()}</>
      </div>
    )
  }

  return renderContent()
}

export default EditSite
