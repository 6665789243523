import ngrokApi from '../api/ngrokApi'
import createDataContext from './createDataContext'

// Reducer
const CashLogsContext = (state, action) => {
  switch (action.type) {
    case 'LOADING':
      return { ...state, loading: true }
    case 'SET_START_DATE':
      return { ...state, startDate: action.payload }
    case 'SET_END_DATE':
      return { ...state, endDate: action.payload }
    case 'SET_REQUESTED_LOGS':
      return { ...state, requestedLogs: action.payload }
    case 'SET_MONEY_IN':
      return { ...state, moneyIn: action.payload }
    case 'SET_MONEY_OUT':
      return { ...state, moneyOut: action.payload }
    case 'SET_TRANSACTION_HISTORY_FOR':
      return { ...state, transactionHistoryFor: action.payload }
    case 'CREATE_COMMISSION_ACCOUNT':
      return { ...state, commissionAccount: action.payload, loading: false }
    case 'FETCH_COMMISSION_ACCOUNT':
      return { ...state, commissionAccount: action.payload, loading: false }
    case 'UPDATE_PAID_COMMISSION_ACCOUNT':
      return { ...state, commissionAccount: action.payload, loading: false }
    default:
      return state
  }
}

// Actions
const setStartDate = (dispatch) => (date) => {
  dispatch({ type: 'SET_START_DATE', payload: date })
}

const setEndDate = (dispatch) => (date) => {
  dispatch({ type: 'SET_END_DATE', payload: date })
}

const setRequestedLogs = (dispatch) => (date) => {
  dispatch({ type: 'SET_REQUESTED_LOGS', payload: date })
}

const setMoneyIn = (dispatch) => (value) => {
  dispatch({ type: 'SET_MONEY_IN', payload: value })
}

const setMoneyOut = (dispatch) => (value) => {
  dispatch({ type: 'SET_MONEY_OUT', payload: value })
}

const setTransactionHistoryFor = (dispatch) => (value) => {
  dispatch({ type: 'SET_TRANSACTION_HISTORY_FOR', payload: value })
}

const createCommissionAccount = (dispatch) => async (data) => {
  dispatch({ type: 'LOADING' })
  try {
    const response = await ngrokApi.post(
      '/creator/create-commission-account',
      data
    )
    dispatch({ type: 'CREATE_COMMISSION_ACCOUNT', payload: response.data })
  } catch (error) {
    dispatch({
      type: 'ADD_ERROR',
      payload: error,
    })
  }
}

const fetchCommissionAccount = (dispatch) => async (data) => {
  dispatch({ type: 'LOADING' })
  try {
    const response = await ngrokApi.post(
      '/creator/fetch-commission-account',
      data
    )
    dispatch({ type: 'FETCH_COMMISSION_ACCOUNT', payload: response.data })
  } catch (error) {
    dispatch({
      type: 'ADD_ERROR',
      payload: error,
    })
  }
}

const updatePaidCommissionAccount = (dispatch) => async (data) => {
  dispatch({ type: 'LOADING' })
  try {
    const response = await ngrokApi.patch(
      '/creator/update-paid-commission-account',
      data
    )
    dispatch({ type: 'UPDATE_PAID_COMMISSION_ACCOUNT', payload: response.data })
  } catch (error) {
    dispatch({
      type: 'ADD_ERROR',
      payload: error,
    })
  }
}

export const { Context, Provider } = createDataContext(
  CashLogsContext,
  {
    setStartDate,
    setEndDate,
    setRequestedLogs,
    setMoneyIn,
    setMoneyOut,
    setTransactionHistoryFor,
    createCommissionAccount,
    fetchCommissionAccount,
    updatePaidCommissionAccount,
  },
  // Initial state
  {
    loading: false,
    startDate: new Date(),
    endDate: new Date(),
    requestedLogs: [],
    moneyIn: 0,
    moneyOut: 0,
    transactionHistoryFor: null,
    commissionAccount: null,
  }
)
