import React, { useContext, useEffect, useState } from 'react'
import moment from 'moment'
import _ from 'lodash'

import LoaderSmall from '../../common/loader/LoaderSmall'
import { Context as CreatorContext } from '../../../context/CreatorContext'
import { fruitsArray } from '../../../symbols/fruitsArray'
import './gamePlay.css'

const GamePlay = () => {
  const [gamePlayTenSorted, setGamePlayTenSorted] = useState([])
  const [showAllCollection, setShowAllCollection] = useState(false)

  const {
    state: { loading, client, clientGamePlay },
    fetchClientGamePlay,
  } = useContext(CreatorContext)

  useEffect(() => {
    if (client) {
      fetchClientGamePlay({ id: client._id })
    }
  }, [client])

  useEffect(() => {
    if (clientGamePlay) {
      let array = _.orderBy(clientGamePlay, [(item) => item.created], ['desc'])
      let arrayTen = array.splice(0, 10)
      setGamePlayTenSorted(arrayTen)
    }
  }, [clientGamePlay])

  const assignSymbol = (symbol) => {
    switch (symbol) {
      case '1':
        return fruitsArray[0].symbol
      case '2':
        return fruitsArray[1].symbol
      case '3':
        return fruitsArray[2].symbol
      case '4':
        return fruitsArray[3].symbol
      case '5':
        return fruitsArray[4].symbol
      case '6':
        return fruitsArray[5].symbol
      case '7':
        return fruitsArray[6].symbol
      case '8':
        return fruitsArray[7].symbol
      case '9':
        return fruitsArray[8].symbol
      case '10':
        return fruitsArray[9].symbol
      case '11':
        return fruitsArray[10].symbol
      default:
        break
    }
  }

  const renderRowResult = (resultArray) => {
    return resultArray.map((result) => {
      return (
        <div className="gamePlayResultRowBed" key={result._id}>
          {!result.quantity ? null : (
            <div className="gamePlayResultRowContainer">
              <div className="gamePlayResultRowSymbolBed">
                {result.quantity} x
              </div>
              <div className="gamePlayResultRowQuantityBed">
                {result.symbol} {assignSymbol(result.symbol)}
              </div>
            </div>
          )}
        </div>
      )
    })
  }

  const renderGamePlay = () => {
    if (gamePlayTenSorted.length < 1) {
      return <div className="listNoData">No game play to display</div>
    }
    let arraySorted = _.orderBy(
      clientGamePlay,
      [(item) => item.created],
      ['desc']
    )
    let array = !showAllCollection ? gamePlayTenSorted : arraySorted
    return array.map((gamePlay) => {
      return (
        <div className="gamePlayDataBed" key={gamePlay._id}>
          <div className="gamePlayDataRowBed">
            <div className="gamePlayDataLeftColBed">
              <div className="gamePlayDataLeftColPair">
                <div className="gamePlayDataRowLabel">Name of game: </div>
                <div className="gamePlayDataValue">{gamePlay.nameOfGame}</div>
              </div>
              <div className="gamePlayDataLeftColPair">
                <div className="gamePlayDataRowLabel">Free spins: </div>
                <div className="gamePlayDataValue">
                  {gamePlay.freeSpinsTotal}
                </div>
              </div>
              <div className="gamePlayDataLeftColPair">
                <div className="gamePlayDataRowLabel">Number of lines: </div>
                <div className="gamePlayDataValue">
                  {gamePlay.numberOfLines}
                </div>
              </div>
              <div className="gamePlayDataLeftColPair">
                <div className="gamePlayDataRowLabel">Bet value: </div>
                <div className="gamePlayDataValue">{gamePlay.betValue}</div>
              </div>
              <div className="gamePlayDataLeftColPair">
                <div className="gamePlayDataRowLabel">Bet total: </div>
                <div className="gamePlayDataValue">{gamePlay.betTotal}</div>
              </div>
              <div className="gamePlayDataLeftColPair">
                <div className="gamePlayDataRowLabel">Prize: </div>
                <div className="gamePlayDataValue">{gamePlay.prize}</div>
              </div>
              <div className="gamePlayDataLeftColPair">
                <div className="gamePlayDataRowLabel">Created: </div>
                <div className="gamePlayDataValue">
                  {moment(gamePlay.created).format('MMMM Do YYYY, h:mm:ss a')}
                </div>
              </div>
            </div>
          </div>
          <div className="gamePlayResulBed">
            <div className="gamePlayResulHeader">Row A</div>
            <div>{renderRowResult(gamePlay.rowAResult)}</div>
          </div>
          <div className="gamePlayResulBed">
            <div className="gamePlayResulHeader">Row B</div>
            <div>{renderRowResult(gamePlay.rowBResult)}</div>
          </div>
          <div className="gamePlayResulBed">
            <div className="gamePlayResulHeader">Row C</div>
            <div>{renderRowResult(gamePlay.rowCResult)}</div>
          </div>
        </div>
      )
    })
  }

  const renderContent = () => {
    if (loading) return <LoaderSmall />
    return (
      <>
        <div className="gamePlayBed">
          <div className="gamePlayHeaderBed">
            {!showAllCollection ? (
              <div
                className="gamePlayShowAllButton"
                onClick={() => setShowAllCollection(true)}
              >
                Show all
              </div>
            ) : (
              <div
                className="gamePlayShowAllButton"
                onClick={() => setShowAllCollection(false)}
              >
                Show 10
              </div>
            )}
          </div>
          {renderGamePlay()}
        </div>
      </>
    )
  }

  return renderContent()
}

export default GamePlay
