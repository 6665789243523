import React, { useContext, useEffect, useState } from 'react'
import { AiOutlineArrowLeft } from 'react-icons/ai'

import LoaderSmall from '../../common/loader/LoaderSmall'
import { Context as CreatorContext } from '../../../context/CreatorContext'

const EditOperator = () => {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [phone1, setPhone1] = useState('')
  const [phone2, setPhone2] = useState('')
  const [phone3, setPhone3] = useState('')
  const [email1, setEmail1] = useState('')
  const [email2, setEmail2] = useState('')
  const [email3, setEmail3] = useState('')

  const {
    state: { loading, error, editFormProps },
    editUser,
    clearError,
    setEditFormShow,
    setEditFormProps,
  } = useContext(CreatorContext)

  useEffect(() => {
    if (editFormProps) {
      if (editFormProps.email.length > 0) setEmail(editFormProps.email)
      if (editFormProps.phone1.length > 0) setPhone1(editFormProps.phone1)
      if (editFormProps.phone2.length > 0) setPhone2(editFormProps.phone2)
      if (editFormProps.phone3.length > 0) setPhone3(editFormProps.phone3)
      if (editFormProps.email1.length > 0) setEmail1(editFormProps.email1)
      if (editFormProps.email2.length > 0) setEmail2(editFormProps.email2)
      if (editFormProps.email3.length > 0) setEmail3(editFormProps.email3)
    }
  }, [editFormProps])

  const submit = () => {
    if (password.length > 0) {
      editUser({
        id: editFormProps._id,
        email,
        password,
        phone1,
        phone2,
        phone3,
        email1,
        email2,
        email3,
        operator: true,
      })
      return
    } else {
      editUser({
        id: editFormProps._id,
        email,
        phone1,
        phone2,
        phone3,
        email1,
        email2,
        email3,
        operator: true,
      })
      return
    }
  }

  const renderError = () => {
    if (!error) return null
    return (
      <div className="formErrorBed">
        <div className="formErrorText">{error}</div>
        <div className="formErrorButtonBed">
          <div className="formErrorButton" onClick={() => clearError()}>
            ok
          </div>
        </div>
      </div>
    )
  }

  const form = () => {
    if (loading) return <LoaderSmall />
    if (error) return null
    return (
      <div>
        <div
          className="formCancelButton"
          onClick={() => {
            setEditFormProps(null)
            setEditFormShow(false)
          }}
        >
          <AiOutlineArrowLeft className="formCancelButtonIcon" />
          <div className="formCancelButtonTextBed">
            <div className="formCancelButtonText">cancel</div>
          </div>
        </div>
        <>
          <div className="formInputHeader">Username</div>
          <input
            className="formInput"
            type="text"
            placeholder="username"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </>
        <>
          <div className="formInputHeader">Password</div>
          <input
            className="formInput"
            type="text"
            placeholder="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </>
        <>
          <div className="formInputHeader">Phone 1</div>
          <input
            className="formInput"
            type="text"
            placeholder="phone 1"
            value={phone1}
            onChange={(e) => setPhone1(e.target.value)}
          />
        </>
        <>
          <div className="formInputHeader">Phone 2</div>
          <input
            className="formInput"
            type="text"
            placeholder="phone 2"
            value={phone2}
            onChange={(e) => setPhone2(e.target.value)}
          />
        </>
        <>
          <div className="formInputHeader">Phone 3</div>
          <input
            className="formInput"
            type="text"
            placeholder="phone 3"
            value={phone3}
            onChange={(e) => setPhone3(e.target.value)}
          />
        </>
        <>
          <div className="formInputHeader">Email 1</div>
          <input
            className="formInput"
            type="text"
            placeholder="email 1"
            value={email1}
            onChange={(e) => setEmail1(e.target.value)}
          />
        </>
        <>
          <div className="formInputHeader">Email2</div>
          <input
            className="formInput"
            type="text"
            placeholder="email 2"
            value={email2}
            onChange={(e) => setEmail2(e.target.value)}
          />
        </>
        <>
          <div className="formInputHeader">Email 3</div>
          <input
            className="formInput"
            type="text"
            placeholder="email 3"
            value={email3}
            onChange={(e) => setEmail3(e.target.value)}
          />
        </>
        <div className="formSubmitButtonBed">
          <div
            className="formSubmitButton"
            onClick={() => {
              submit()
              setEditFormShow(false)
            }}
          >
            submit
          </div>
        </div>
      </div>
    )
  }

  const renderContent = () => {
    return (
      <div className="formBed">
        <div className="formHeader">Edit operator</div>
        <>{renderError()}</>
        <>{form()}</>
      </div>
    )
  }

  return renderContent()
}

export default EditOperator
