import React, { useContext, useEffect, useState } from 'react'
import Calendar from 'react-calendar'
import moment from 'moment'

import { Context as CashLogsContext } from '../../../context/CashLogsContext'
import CalculateDailyCash from './CalculateDailyCash'
import './cashLogs.css'
import './calendar.css'

const CashLogs = () => {
  const [showStartCalendar, setShowStartCalendar] = useState(true)
  const [showEndCalendar, setShowEndCalendar] = useState(false)
  const [showRequestedLogs, setShowRequestedLogs] = useState(false)
  const [moneyNet, setMoneyNet] = useState(0)

  const {
    state: { startDate, endDate, moneyIn, moneyOut },
    setStartDate,
    setEndDate,
    setRequestedLogs,
    setMoneyIn,
    setMoneyOut,
  } = useContext(CashLogsContext)

  useEffect(() => {
    setMoneyNet(moneyIn - moneyOut)
  }, [moneyIn, moneyOut])

  const renderRequestedCashLogs = () => {
    if (!showRequestedLogs) return null
    return (
      <div className="requestedLogsBed">
        <div className="requestedLogsContainer">
          <div className="requestedLogsDatesBed">
            <div className="requestedLogsDates">
              <div className="requestedLogsDatesCell">
                <div className="requestedLogsDateText">from</div>
                <br />
                <div className="requestedLogsDateText">
                  {moment(startDate).format('YYYY-MM-DD')}
                </div>
              </div>
              <div className="requestedLogsDatesCell">
                <div className="requestedLogsDateText">to</div>
                <br />
                <div className="requestedLogsDateText">
                  {moment(endDate).format('YYYY-MM-DD')}
                </div>
              </div>
            </div>
            <div className="requestedLogsChart">
              <div className="requestedLogsChartText">
                IN :{' '}
                {moneyIn.toString().replace(/.(?=(...........)*..$)/g, '$&,')}
              </div>
              <div className="requestedLogsChartText">
                OUT :{' '}
                {moneyOut.toString().replace(/.(?=(...........)*..$)/g, '$&,')}
              </div>
              <div className="requestedLogsChartText">
                NET :{' '}
                {moneyNet.toString().replace(/.(?=(...........)*..$)/g, '$&,')}
              </div>
            </div>
            <div className="requestedLogsButtonBed">
              <div
                className="requestedLogsButton"
                onClick={() => {
                  setShowRequestedLogs(false)
                  setRequestedLogs([])
                  setMoneyIn(0)
                  setMoneyOut(0)
                }}
              >
                done
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  const calendar = () => {
    if (showRequestedLogs) return null
    if (showStartCalendar || showEndCalendar) {
      return (
        <div className="cashLogsCalendarBed">
          <div className="cashLogsCalendarButtonBed">
            <div className="cashLogsSelectedDatesHeading">
              {showStartCalendar ? 'from' : 'to'}
            </div>
          </div>
          <div className="cashLogsCalendarContainer">
            <Calendar
              onChange={showStartCalendar ? setStartDate : setEndDate}
              value={showStartCalendar ? startDate : endDate}
            />
          </div>
          {startDate > endDate ? (
            <div className="cashLogsCalendarDatesError">dates are invalid</div>
          ) : (
            <div className="cashLogsCalendarButtonBed">
              <div
                className="cashLogsButton"
                onClick={() => setShowRequestedLogs(true)}
              >
                submit
              </div>
            </div>
          )}
        </div>
      )
    }
  }

  const renderSelectedDates = () => {
    if (showRequestedLogs) return null
    return (
      <div className="cashLogsSelectedDatesBed">
        <div className="cashLogsSelectedDatesContainer">
          <div className="cashLogsSelectedDatesHeading">from</div>
          <div className="cashLogsSelectedDatesText">
            {moment(startDate).format('YYYY-MM-DD')}
          </div>
          <div className="cashLogsCalendarButtonBed">
            <div
              className={
                showStartCalendar ? 'cashLogsButton' : 'cashLogsButtonInactive'
              }
              onClick={() => {
                setShowEndCalendar(false)
                setShowStartCalendar(true)
              }}
            >
              from date
            </div>
          </div>
        </div>
        <div className="cashLogsSelectedDatesContainer">
          <div className="cashLogsSelectedDatesHeading">to</div>
          <div className="cashLogsSelectedDatesText">
            {moment(endDate).format('YYYY-MM-DD')}
          </div>
          <div className="cashLogsCalendarButtonBed">
            <div
              className={
                showEndCalendar ? 'cashLogsButton' : 'cashLogsButtonInactive'
              }
              onClick={() => {
                setShowStartCalendar(false)
                setShowEndCalendar(true)
              }}
            >
              to date
            </div>
          </div>
        </div>
      </div>
    )
  }

  const renderContent = () => {
    return (
      <div className="bed">
        <div className="cashLogsBed">
          <div className="cashLogsContainer">
            {renderRequestedCashLogs()}
            {renderSelectedDates()}
            {calendar()}
          </div>
        </div>
      </div>
    )
  }

  return (
    <>
      <CalculateDailyCash />
      {renderContent()}
    </>
  )
}

export default CashLogs
