import React, { useContext, useEffect, useState } from 'react'

import { Context as CashLogsContext } from '../../../../context/CashLogsContext'
import { Context as CreatorContext } from '../../../../context/CreatorContext'
import CalculateCommissionAccount from './CaculateCommissionAccount'
import './commissionAccount.css'

const CommissionAccountCreate = () => {
  const [transactions, setTransactions] = useState([])

  const {
    state: { moneyIn, moneyOut },
    createCommissionAccount,
  } = useContext(CashLogsContext)

  const {
    state: { siteTransactionHistory, site, operator },
  } = useContext(CreatorContext)

  useEffect(() => {
    if (siteTransactionHistory) {
      let array = siteTransactionHistory.filter((siteTrans) => {
        return siteTrans.commissionPaid === false
      })
      setTransactions(array)
    }
  }, [siteTransactionHistory])

  const renderContent = () => {
    let profit = moneyIn - moneyOut
    let profitFormatted = (profit / 100).toFixed(2)
    let commission = (site.commissionPercentage / 100) * profit
    let commissionForatted = (commission / 100).toFixed(2)
    return (
      <div className="commissionAccountCreatorButtonBed">
        <div
          className="commissionAccountCreatorButton"
          onClick={() => {
            createCommissionAccount({
              siteNumber: site.siteNumber,
              operatorId: operator._id,
              operatorUsername: operator.username,
              valueIn: (moneyIn / 100).toFixed(2),
              valueOut: (moneyOut / 100).toFixed(2),
              profit: profitFormatted,
              commissionPercentage: site.commissionPercentage,
              commissionValue: commissionForatted,
            })
          }}
        >
          Create account
        </div>
      </div>
    )
  }

  return transactions.length < 1 ? null : renderContent()
  // return renderContent()
}

export default CommissionAccountCreate
