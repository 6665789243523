import React, { useContext, useEffect, useState } from 'react'

import { Context as CashLogsContext } from '../../../../context/CashLogsContext'
import { Context as CreatorContext } from '../../../../context/CreatorContext'
import CalculateCommissionAccount from './CaculateCommissionAccount'
import CommissionAccountCreate from './CommissionAccountCreate'
import CommissionAccountList from './CommissionAccountList'
import './commissionAccount.css'

const CommissionAccount = () => {
  const [commissionPercentage, setCommissionPercentage] = useState(15)
  const [transactions, setTransactions] = useState([])

  const {
    state: { moneyIn, moneyOut },
  } = useContext(CashLogsContext)

  const {
    state: { siteTransactionHistory, site },
    fetchSiteTransactionHistory,
  } = useContext(CreatorContext)

  useEffect(() => {
    if (site) {
      setCommissionPercentage(site.commissionPercentage)
    }
  }, [site])

  useEffect(() => {
    if (siteTransactionHistory) {
      let array = siteTransactionHistory.filter((siteTrans) => {
        return siteTrans.commissionPaid === false
      })
      setTransactions(array)
    }
  }, [siteTransactionHistory])

  const renderCommission = () => {
    let profit = moneyIn - moneyOut
    let profitFormatted = (profit / 100).toFixed(2)
    let commission = (commissionPercentage / 100) * profit
    let commissionForatted = (commission / 100).toFixed(2)
    return (
      <div className="commissionBed">
        <div className="commissionContainer">
          <div className="commissionRow">
            <div className="baitTriggerDataLabel">Money IN</div>
            <div className="baitTriggerDataText">
              {(moneyIn / 100).toFixed(2)}
            </div>
          </div>
          <div className="commissionRow">
            <div className="baitTriggerDataLabel">Money OUT</div>
            <div className="baitTriggerDataText">
              {(moneyOut / 100).toFixed(2)}
            </div>
          </div>
          <div className="commissionRow">
            <div className="baitTriggerDataLabel">Profit</div>
            <div className="baitTriggerDataText">{profitFormatted}</div>
          </div>
          <div className="commissionRow">
            <div className="baitTriggerDataLabel">Commission</div>
            <div className="baitTriggerDataText">{commissionForatted}</div>
          </div>
        </div>
      </div>
    )
  }

  const unpaidTransactionList = () => {
    return transactions.map((tra) => {
      return (
        <div className="unpaidTransactionRow" key={tra._id}>
          <div className="unpaidTransactionText">{tra.clientUsername}</div>
          <div className="unpaidTransactionText">{tra.type}</div>
          <div className="unpaidTransactionText">{tra.value}</div>
          <div className="unpaidTransactionText">{tra.clientOldBalance}</div>
          <div className="unpaidTransactionText">{tra.clientNewBalance}</div>
          <div className="unpaidTransactionText">
            {tra.commissionBilled ? 'true' : 'false'}
          </div>
          <div className="unpaidTransactionText">
            {tra.commissionPaid ? 'true' : 'false'}
          </div>
        </div>
      )
    })
  }

  const renderUnpaidTransactions = () => {
    return (
      <div className="unpaidTransactionBed">
        <div className="unpaidTransactionContainer">
          <div
            className="refreshButton"
            onClick={() =>
              fetchSiteTransactionHistory({ siteNumber: site.siteNumber })
            }
          >
            ↻
          </div>
          <div className="unpaidTransactionRefreshButtonSpacer"></div>
          <div></div>
          <div className="unpaidTransactionHeaderBed">
            <div className="unpaidTransactionHeader">Username</div>
            <div className="unpaidTransactionHeader">Type</div>
            <div className="unpaidTransactionHeader">Value</div>
            <div className="unpaidTransactionHeader">Old balance</div>
            <div className="unpaidTransactionHeader">New balance</div>
            <div className="unpaidTransactionHeader">Commission billed</div>
            <div className="unpaidTransactionHeader">Commission paid</div>
          </div>
          {unpaidTransactionList()}
        </div>
      </div>
    )
  }

  const renderContent = () => {
    return (
      <div className="commissionAccountBed">
        <div className="commissionAccountHeader">
          Commission account ({commissionPercentage}%) according to unpaid
          commission transaction
        </div>
        {renderCommission()}
        <CommissionAccountCreate />
        <CommissionAccountList />
        {renderUnpaidTransactions()}
      </div>
    )
  }

  return (
    <>
      <CalculateCommissionAccount />
      {renderContent()}
    </>
  )
}

export default CommissionAccount
