import React, { useContext } from 'react'

import NavHeader from '../../common/navHeader/NavHeader'
import { Context as NavContext } from '../../../context/NavContext'
import { Context as CreatorContext } from '../../../context/CreatorContext'
import './operatorDetail.css'

const OperatorDetail = () => {
  const { setVisibleComponent } = useContext(NavContext)

  const {
    state: { operator },
  } = useContext(CreatorContext)

  const navToOperatorSiteListScreen = () => {
    return (
      <div className="dashboardNavLinkButtonBed">
        <div
          className="dashboardNavLinkButton"
          onClick={() => setVisibleComponent('operatorSiteList')}
        >
          view sites
        </div>
      </div>
    )
  }

  const operatorsDetails = () => {
    return (
      <div className="dashWindowBed">
        <div className="dashWindowHeader">Operator info</div>
        <div className="usersListDataRow">
          <div className="usersListDataLabel">Username</div>
          <div className="usersListDataText">{operator.username}</div>
        </div>
        <div className="usersListDataRow">
          <div className="usersListDataLabel">ID</div>
          <div className="usersListDataText">{operator._id}</div>
        </div>
        <div className="usersListDataRow">
          <div className="usersListDataLabel">Phone 1</div>
          <div className="usersListDataText">{operator.phone1}</div>
        </div>
        <div className="usersListDataRow">
          <div className="usersListDataLabel">Phone 2</div>
          <div className="usersListDataText">{operator.phone2}</div>
        </div>
        <div className="usersListDataRow">
          <div className="usersListDataLabel">Phone 3</div>
          <div className="usersListDataText">{operator.phone3}</div>
        </div>
        <div className="usersListDataRow">
          <div className="usersListDataLabel">Email 1</div>
          <div className="usersListDataText">{operator.email1}</div>
        </div>
        <div className="usersListDataRow">
          <div className="usersListDataLabel">Email 2</div>
          <div className="usersListDataText">{operator.email2}</div>
        </div>
        <div className="usersListDataRow">
          <div className="usersListDataLabel">Email 3</div>
          <div className="usersListDataText">{operator.email3}</div>
        </div>
      </div>
    )
  }

  const renderContent = () => {
    return (
      <>
        <NavHeader navTo="operatorList" headerText="Operator detail" />
        <div className="operatorDetailDetailBed">{operatorsDetails()}</div>
        <div className="operatorDetailOperatorSiteBed">
          <div className="dashWindowBed">
            <div className="dashWindowHeader">Operator sites</div>
            {navToOperatorSiteListScreen()}
          </div>
        </div>
      </>
    )
  }

  return renderContent()
}

export default OperatorDetail
