import createDataContext from './createDataContext'

// Reducer
const socketReducer = (state, action) => {
  switch (action.type) {
    case 'SET_SOCKET_CHANEL':
      return { ...state, socketChanel: action.payload }
    case 'SET_SOCKET_DATA_FROM_CREATOR':
      return { ...state, socketDataFromCreator: action.payload }
    case 'SET_SOCKET_DATA_FROM_CLIENT':
      return { ...state, socketDataFromClient: action.payload }
    default:
      return state
  }
}

// Actions
const setSocketChanel = (dispatch) => (value) => {
  dispatch({ type: 'SET_SOCKET_CHANEL', payload: value })
}

const setSocketDataFromCreator = (dispatch) => (value) => {
  dispatch({ type: 'SET_SOCKET_DATA_FROM_CREATOR', payload: value })
}

const setSocketDataFromClient = (dispatch) => (value) => {
  dispatch({ type: 'SET_SOCKET_DATA_FROM_CLIENT', payload: value })
}

export const { Provider, Context } = createDataContext(
  socketReducer,
  {
    setSocketChanel,
    setSocketDataFromCreator,
    setSocketDataFromClient,
  },
  {
    socketChanel: '',
    socketDataFromCreator: {},
    socketDataFromClient: {},
  }
)
