import React, { useContext, useEffect } from 'react'

import { Context as SocketContext } from '../../../../context/SocketContext'
import { Context as CreatorContext } from '../../../../context/CreatorContext'
import { Context as BaitContext } from '../../../../context/BaitContext'
import './siteBaitTriggerInfo.css'

const SiteBaitTriggerInfo = () => {
  const { setSocketChanel, setSocketDataFromCreator } =
    useContext(SocketContext)

  const {
    state: { site, clients },
  } = useContext(CreatorContext)

  const {
    state: { baitTiriggerData },
    setBaitTiriggerData,
  } = useContext(BaitContext)

  useEffect(() => {
    if (site) {
      setSocketChanel(site.siteNumber)
    }
  }, [site])

  const renderBaitTriggerData = () => {
    if (!baitTiriggerData || baitTiriggerData.length < 1) return null
    const { jackpotTriggerValue, progresiveTriggerValue } = baitTiriggerData[0]
    let formattedJackpotTriggerValue = (jackpotTriggerValue / 100).toFixed(2)
    let formattedProgresiveTriggerValue = (
      progresiveTriggerValue / 100
    ).toFixed(2)
    return (
      <div className="baitTriggerDataContainer">
        <div className="baitTriggerDataRow">
          <div className="baitTriggerDataLabel">Site jackpot trigger value</div>
          <div className="baitTriggerDataText">
            {formattedJackpotTriggerValue}
          </div>
        </div>
        <div className="baitTriggerDataRow">
          <div className="baitTriggerDataLabel">
            Site progresive trigger value
          </div>
          <div className="baitTriggerDataText">
            {formattedProgresiveTriggerValue}
          </div>
        </div>
      </div>
    )
  }

  const renderTriggerDataArray = () => {
    if (!baitTiriggerData || baitTiriggerData.lenght < 1) return null
    return baitTiriggerData.map((item) => {
      return (
        <div className="baitTriggerDataContainer" key={item.username}>
          <div className="baitTriggerDataLight">
            <div
              className={
                item.gameCredits > 1000
                  ? 'baitTriggerDataLightGreen'
                  : 'baitTriggerDataLightRed'
              }
            ></div>
          </div>
          <div className="baitTriggerDataRow">
            <div className="baitTriggerDataLabel">Username</div>
            <div className="baitTriggerDataText">{item.username}</div>
          </div>
          <div className="baitTriggerDataRow">
            <div className="baitTriggerDataLabel">Game credits</div>
            <div className="baitTriggerDataText">
              {(item.gameCredits / 100).toFixed(2)}
            </div>
          </div>
        </div>
      )
    })
  }

  const renderClientLength = () => {
    if (!clients || clients.length < 1) return null
    if (!baitTiriggerData || baitTiriggerData.length < 1) return null
    return (
      <div className="clientLengthBed">
        <div className="clientLengthText">
          Site clients: {baitTiriggerData.length} of {clients.length}
        </div>
      </div>
    )
  }

  const renderContent = () => {
    return (
      <div className="siteBaitTriggerInfoBed">
        <div className="siteBaitTriggerInfoHeaderBed">
          <div className="baitListHeader">Site bait trigger info</div>
          <div
            className="refreshButton"
            onClick={() => {
              setBaitTiriggerData([])
              setSocketDataFromCreator({
                subject: 'fetchBaitTriggerData',
                fetchBaitTriggerData: true,
              })
            }}
          >
            ↻
          </div>
        </div>
        {renderBaitTriggerData()}
        {renderClientLength()}
        <div className="baitTriggerDataBed">{renderTriggerDataArray()}</div>
      </div>
    )
  }

  return renderContent()
}

export default SiteBaitTriggerInfo
