import React, { useContext, useEffect, useState } from 'react'
import { AiOutlineArrowLeft } from 'react-icons/ai'

import LoaderSmall from '../../common/loader/LoaderSmall'
import { Context as CreatorContext } from '../../../context/CreatorContext'

const EditClient = () => {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')

  const {
    state: { loading, error, editFormProps },
    editUser,
    clearError,
    setEditFormShow,
    setEditFormProps,
  } = useContext(CreatorContext)

  useEffect(() => {
    if (editFormProps) {
      if (editFormProps.email.length > 0) setEmail(editFormProps.email)
    }
  }, [editFormProps])

  const submit = () => {
    if (password.length > 0) {
      editUser({
        id: editFormProps._id,
        email,
        password,
        client: true,
        siteNumber: editFormProps.siteNumber,
      })
      return
    } else {
      editUser({
        id: editFormProps._id,
        email,
        client: true,
      })
      return
    }
  }

  const renderError = () => {
    if (!error) return null
    return (
      <div className="formErrorBed">
        <div className="formErrorText">{error}</div>
        <div className="formErrorButtonBed">
          <div className="formErrorButton" onClick={() => clearError()}>
            ok
          </div>
        </div>
      </div>
    )
  }

  const form = () => {
    if (loading) return <LoaderSmall />
    if (error) return null
    return (
      <div>
        <div
          className="formCancelButton"
          onClick={() => {
            setEditFormProps(null)
            setEditFormShow(false)
          }}
        >
          <AiOutlineArrowLeft className="formCancelButtonIcon" />
          <div className="formCancelButtonTextBed">
            <div className="formCancelButtonText">cancel</div>
          </div>
        </div>
        <>
          <div className="formInputHeader">Site number</div>
          <div className="formNoneEditableField">
            {editFormProps.siteNumber}
          </div>
        </>
        <>
          <div className="formInputHeader">Username</div>
          <input
            className="formInput"
            type="text"
            placeholder="username"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </>
        <>
          <div className="formInputHeader">Password</div>
          <input
            className="formInput"
            type="text"
            placeholder="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </>
        <div className="formSubmitButtonBed">
          <div
            className="formSubmitButton"
            onClick={() => {
              submit()
              setEditFormShow(false)
            }}
          >
            submit
          </div>
        </div>
      </div>
    )
  }

  const renderContent = () => {
    return (
      <div className="formBed">
        <div className="formHeader">Edit client</div>
        <>{renderError()}</>
        <>{form()}</>
      </div>
    )
  }

  return renderContent()
}

export default EditClient
