import _ from 'lodash'
import React, { useContext, useEffect, useState } from 'react'
import { Bar } from 'react-chartjs-2'
import { CategoryScale } from 'chart.js'
import Chart from 'chart.js/auto'
import { Context as CreatorContext } from '../../../context/CreatorContext'
import './payChart.css'

Chart.register(CategoryScale)

const PayChart = () => {
  const [dataPrizeArray, setDataPrizeArray] = useState([])
  const [dataBetTotalArray, setDataBetTotalArray] = useState([])
  const [gamePlayTwoHundredSorted, setGamePlayTwoHundredSorted] = useState([])
  const [allBetValue, setAllBetValue] = useState(0)
  const [allPrizeValue, setAllPrizeValue] = useState(0)

  const {
    state: { clientGamePlay },
  } = useContext(CreatorContext)

  useEffect(() => {
    if (clientGamePlay) {
      let array = _.orderBy(clientGamePlay, [(item) => item.created], ['desc'])
      setGamePlayTwoHundredSorted(array)
      const allBet = array.map((arr) => arr.betTotal).reduce((a, b) => a + b)
      setAllBetValue(allBet)
      const allPrize = array.map((arr) => arr.prize).reduce((a, b) => a + b)
      setAllPrizeValue(allPrize)
    }
  }, [clientGamePlay])

  useEffect(() => {
    if (gamePlayTwoHundredSorted.length > 0) {
      let prizeArray = gamePlayTwoHundredSorted.map((game) => {
        return game.prize
      })
      setDataPrizeArray(prizeArray)
      let betTotalArray = gamePlayTwoHundredSorted.map((game) => {
        return game.betTotal
      })
      setDataBetTotalArray(betTotalArray)
    }
  }, [gamePlayTwoHundredSorted])

  const barChartData = {
    labels: dataBetTotalArray,
    datasets: [
      {
        data: dataPrizeArray,
        label: 'Payout',
        borderColor: '#ff455e',
        backgroundColor: '#ff455e',
        fill: true,
      },
      {
        data: dataBetTotalArray,
        label: 'Bet value',
        borderColor: '#3333ff',
        backgroundColor: '#8fce00',
        fill: true,
      },
    ],
  }

  const barChart = (
    <Bar
      type="bar"
      width={130}
      height={50}
      options={{
        title: {
          display: true,
          text: 'Payout graph',
          fontSize: 15,
        },
        legend: {
          display: true, //Is the legend shown?
          position: 'top', //Position of the legend.
        },
      }}
      data={barChartData}
    />
  )
  return (
    <div className="payChartHeadingBed">
      <div className="payChartHeadingText">
        DATA from {clientGamePlay.length} most recent game plays
      </div>
      <div className="payChartHeadingText">
        Bet value:{' '}
        {allBetValue.toString().replace(/.(?=(...........)*..$)/g, '$&,')}
      </div>
      <div className="payChartHeadingText">
        Prize value:{' '}
        {allPrizeValue.toString().replace(/.(?=(...........)*..$)/g, '$&,')}
      </div>
      <div className="payChartHeadingText">
        difference:{' '}
        {(allPrizeValue - allBetValue)
          .toString()
          .replace(/.(?=(...........)*..$)/g, '$&,')}
      </div>
      <div>{barChart}</div>
    </div>
  )
}

export default PayChart
