import React, { useContext, useEffect, useState } from 'react'
import { AiOutlineArrowLeft } from 'react-icons/ai'

import LoaderSmall from '../../common/loader/LoaderSmall'
import { Context as CreatorContext } from '../../../context/CreatorContext'

const RegisterCashier = () => {
  const [showForm, setShowForm] = useState(false)
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')

  const {
    state: { loading, error, success, site },
    registerCashier,
    clearError,
  } = useContext(CreatorContext)

  const submit = () => {
    registerCashier({
      email,
      password,
      siteNumber: site.siteNumber,
    })
  }

  const renderError = () => {
    if (!error) return null
    return (
      <div className="formErrorBed">
        <div className="formErrorText">{error}</div>
        <div className="formErrorButtonBed">
          <div className="formErrorButton" onClick={() => clearError()}>
            ok
          </div>
        </div>
      </div>
    )
  }

  const button = () => {
    if (error || success) return null
    if (showForm) {
      return (
        <div className="formCancelButton" onClick={() => setShowForm(false)}>
          <AiOutlineArrowLeft className="formCancelButtonIcon" />
          <div className="formCancelButtonTextBed">
            <div className="formCancelButtonText">cancel</div>
          </div>
        </div>
      )
    }
    return (
      <div className="formOpenButtonBed">
        <div className="formOpenButton" onClick={() => setShowForm(true)}>
          register cashier
        </div>
      </div>
    )
  }

  const form = () => {
    if (loading) return <LoaderSmall />
    if (!showForm || error || success) return null
    return (
      <div>
        <>
          <div className="formInputHeader">Username</div>
          <input
            className="formInput"
            type="text"
            placeholder="username"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </>
        <>
          <div className="formInputHeader">Password</div>
          <input
            className="formInput"
            type="text"
            placeholder="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </>
        <div className="formSubmitButtonBed">
          <div className="formSubmitButton" onClick={() => submit()}>
            submit
          </div>
        </div>
      </div>
    )
  }

  const renderContent = () => {
    return (
      <div className={!showForm ? 'formBedNoBorder' : 'formBed'}>
        <>{renderError()}</>
        <>{button()}</>
        <>{form()}</>
      </div>
    )
  }

  return renderContent()
}

export default RegisterCashier
