import React, { useContext } from 'react'

import NavHeader from '../../common/navHeader/NavHeader'
import { Context as CreatorContext } from '../../../context/CreatorContext'
import Loader from '../../common/loader/Loader'
import OperatorDashWindow from '../../dashboardComponents/operatorDashWindow/OperatorDashWindow'
import './dashboard.css'

const Dashboard = () => {
  const {
    state: { loading },
  } = useContext(CreatorContext)

  const renderContent = () => {
    if (loading) return <Loader />
    return (
      <>
        <NavHeader />
        <div className="dashboardBed">
          <OperatorDashWindow />
        </div>
      </>
    )
  }

  return renderContent()
}

export default Dashboard
