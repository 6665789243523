import React, { useState, useContext, useEffect } from 'react'
import moment from 'moment'

import Loader from '../../common/loader/Loader'
import NavHeader from '../../common/navHeader/NavHeader'
import CreateSite from '../../forms/site/CreateSite'

import { Context as NavContext } from '../../../context/NavContext'
import { Context as CreatorContext } from '../../../context/CreatorContext'
import EditSite from '../../forms/site/EditSite'

const OperatorSiteList = () => {
  const [search, setSearch] = useState('')
  const [sitesFiltered, setSitesFiltered] = useState([])
  const [showPreDeleteNoteToId, setShowPreDeleteNoteToId] = useState()

  const { setVisibleComponent } = useContext(NavContext)

  const {
    state: { loading, success, operator, sites, editFormShow },
    setSiteSelected,
    fetchOperatorsSites,
    deleteSite,
    clearSuccess,
    setEditFormShow,
    setEditFormProps,
  } = useContext(CreatorContext)

  useEffect(() => {
    fetchOperatorsSites({ id: operator._id })
  }, [])

  useEffect(() => {
    if (success) {
      fetchOperatorsSites({ id: operator._id })
      clearSuccess()
    }
  }, [success])

  useEffect(() => {
    if (sites) {
      if (sites.length > 0) {
        if (search.length > 0) {
          setSitesFiltered(
            sites.filter((site) => site.siteNumber.includes(search))
          )
        } else {
          setSitesFiltered(sites)
        }
      }
    }
  }, [sites, search])

  const perDelteNote = (id) => {
    return (
      <div className="usersListPerDeleteNoteBed">
        <div className="usersListPerDeleteNoteContainer">
          <div className="usersListPerDeleteNote">Confirm delete?</div>
          <div className="listCellButtonBed">
            <div className="listCellButton" onClick={() => deleteSite({ id })}>
              Yes
            </div>
            <div
              className="listCellButton"
              onClick={() => setShowPreDeleteNoteToId()}
            >
              No
            </div>
          </div>
        </div>
      </div>
    )
  }

  const listButtonSelector = (site) => {
    if (site._id === showPreDeleteNoteToId) {
      return perDelteNote(site._id)
    }
    return (
      <div className="listCellButtonBed">
        <div
          className="listCellButton"
          onClick={() => {
            setEditFormProps(site)
            setEditFormShow(true)
          }}
        >
          edit
        </div>
        <div
          className="listCellButton"
          onClick={() => setShowPreDeleteNoteToId(site._id)}
        >
          delete
        </div>
        <div
          className="listCellButton"
          onClick={() => {
            setSiteSelected(site)
            setVisibleComponent('siteDetail')
          }}
        >
          expand
        </div>
      </div>
    )
  }

  const searchBar = () => {
    return (
      <div className="searchInputBed">
        <input
          className="searchInput"
          type="text"
          placeholder="search site 'site number'"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        />
      </div>
    )
  }

  const list = () => {
    if (!sitesFiltered || sitesFiltered.length < 1) return null
    return sitesFiltered.map((site) => {
      return (
        <div className="usersListCell" key={site._id}>
          <div className="usersListDataRow">
            <div className="usersListDataLabel">Site type</div>
            <div className="usersListDataText">{site.type}</div>
          </div>
          <div className="usersListDataRow">
            <div className="usersListDataLabel">Site number</div>
            <div className="usersListDataText">{site.siteNumber}</div>
          </div>
          <div className="usersListDataRow">
            <div className="usersListDataLabel">Town</div>
            <div className="usersListDataText">{site.town}</div>
          </div>
          <div className="usersListDataRow">
            <div className="usersListDataLabel">Street</div>
            <div className="usersListDataText">{site.street}</div>
          </div>
          <div className="usersListDataRow">
            <div className="usersListDataLabel">Percentage</div>
            <div className="usersListDataText">{site.percentage}</div>
          </div>
          <div className="usersListDataRow">
            <div className="usersListDataLabel">Commission percentage</div>
            <div className="usersListDataText">{site.commissionPercentage}</div>
          </div>
          <div className="usersListDataRow">
            <div className="usersListDataLabel">Created</div>
            <div className="usersListDataText">
              {moment(site.created).format('MMMM Do YYYY, h:mm:ss a')}
            </div>
          </div>
          <div className="usersListButtonSelectorBed">
            {listButtonSelector(site)}
          </div>
        </div>
      )
    })
  }

  const renderContent = () => {
    if (loading) return <Loader />
    if (editFormShow) {
      return (
        <div className="listEditFormBed">
          <EditSite />
        </div>
      )
    }
    return (
      <>
        <NavHeader navTo="operatorList" headerText="Operator site list" />
        <div className="listForHeader">
          {!operator ? null : `username: ${operator.username}`}
        </div>
        <div className="listSearchBed">
          {searchBar()}
          <div className="listRegisterOrCreateBed">
            <CreateSite />
          </div>
        </div>
        <div className="listBed">
          <div className="listContainer">{list()}</div>
        </div>
      </>
    )
  }

  return renderContent()
}

export default OperatorSiteList
