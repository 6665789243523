import React, { useContext, useState, useEffect } from 'react'
import moment from 'moment'

import NavHeader from '../../common/navHeader/NavHeader'
import GamePlay from '../../clientDetailComponents/gamePlay/GamePlay'
import TransactionHistory from '../../clientDetailComponents/transactionHistory/TransactionHistory'
import PayChart from '../../clientDetailComponents/gamePlayGraph/PayChart'
import { Context as CreatorContext } from '../../../context/CreatorContext'
import './clientDetail.css'

const ClientDetail = () => {
  const [contentSelected, setContentSelected] = useState('transactions')

  const {
    state: { client },
    fetchClientGamePlay,
  } = useContext(CreatorContext)

  useEffect(() => {
    if (client) {
      fetchClientGamePlay({ id: client._id })
    }
  }, [client])

  const siteInfo = () => {
    return (
      <div className="siteDetailSiteInfoBed">
        <div className="dashWindowBed">
          <div className="dashWindowHeader">Client info</div>
          <div className="usersListDataRow">
            <div className="usersListDataLabel">Site number</div>
            <div className="usersListDataText">{client.siteNumber}</div>
          </div>
          <div className="usersListDataRow">
            <div className="usersListDataLabel">ID</div>
            <div className="usersListDataText">{client._id}</div>
          </div>
          <div className="usersListDataRow">
            <div className="usersListDataLabel">Username</div>
            <div className="usersListDataText">{client.username}</div>
          </div>
          <div className="usersListDataRow">
            <div className="usersListDataLabel">Credits</div>
            <div className="usersListDataText">{client.credits}</div>
          </div>
          <div className="usersListDataRow">
            <div className="usersListDataLabel">Created</div>
            <div className="usersListDataText">
              {moment(client.created).format('MMMM Do YYYY, h:mm:ss a')}
            </div>
          </div>
        </div>
      </div>
    )
  }

  const contentSelectorButton = () => {
    return (
      <div className="siteDetailSelectorButtonBed">
        <div
          className={
            contentSelected === 'transactions'
              ? 'siteDetailSelectorButtonActive'
              : 'siteDetailSelectorButton'
          }
          onClick={() => setContentSelected('transactions')}
        >
          transactions
        </div>
        <div
          className={
            contentSelected === 'gamePlay'
              ? 'siteDetailSelectorButtonActive'
              : 'siteDetailSelectorButton'
          }
          onClick={() => setContentSelected('gamePlay')}
        >
          game play
        </div>
        <div
          className={
            contentSelected === 'payChart'
              ? 'siteDetailSelectorButtonActive'
              : 'siteDetailSelectorButton'
          }
          onClick={() => setContentSelected('payChart')}
        >
          pay chart
        </div>
      </div>
    )
  }

  const contentSelecter = () => {
    switch (contentSelected) {
      case 'transactions':
        return <TransactionHistory />
      case 'gamePlay':
        return <GamePlay />
      case 'payChart':
        return <PayChart />
      default:
        break
    }
  }

  const renderContent = () => {
    return (
      <>
        <NavHeader navTo="siteDetail" headerText="Client details" />
        {siteInfo()}
        {contentSelectorButton()}
        {contentSelecter()}
      </>
    )
  }

  return renderContent()
}

export default ClientDetail
