import React, { useContext, useEffect, useState } from 'react'
import _ from 'lodash'
import moment from 'moment'

import Loader from '../../common/loader/Loader'
import NavHeader from '../../common/navHeader/NavHeader'
import CashLogs from '../../forms/cashLogs/CashLogs'
import { Context as CreatorContext } from '../../../context/CreatorContext'
import { Context as CashLogsContext } from '../../../context/CashLogsContext'
import './cashierDetail.css'

const CashierDetail = () => {
  const [contentSelected, setContentSelected] = useState('transactions')
  const [arrayTenSorted, setArrayTenSorted] = useState([])
  const [showAllCollection, setShowAllCollection] = useState(false)

  const {
    state: { loading, cashier, cashierTransactionHistory },
    fetchCashierTransactionHistory,
  } = useContext(CreatorContext)

  const { setTransactionHistoryFor } = useContext(CashLogsContext)

  useEffect(() => {
    if (cashier) fetchCashierTransactionHistory({ id: cashier._id })
  }, [cashier])

  useEffect(() => {
    if (cashierTransactionHistory) {
      let array = _.orderBy(
        cashierTransactionHistory,
        [(item) => item.created],
        ['desc']
      )
      let arrayTen = array.splice(0, 10)
      setArrayTenSorted(arrayTen)
    }
  }, [cashierTransactionHistory])

  const contentSelectorButton = () => {
    return (
      <div className="siteDetailSelectorButtonBed">
        <div
          className={
            contentSelected === 'transactions'
              ? 'siteDetailSelectorButtonActive'
              : 'siteDetailSelectorButton'
          }
          onClick={() => setContentSelected('transactions')}
        >
          transactions
        </div>
        <div
          className={
            contentSelected === 'cash logs'
              ? 'siteDetailSelectorButtonActive'
              : 'siteDetailSelectorButton'
          }
          onClick={() => {
            setTransactionHistoryFor('cashier')
            setContentSelected('cash logs')
          }}
        >
          cash logs
        </div>
        <div
          className={
            contentSelected === 'bait'
              ? 'siteDetailSelectorButtonActive'
              : 'siteDetailSelectorButton'
          }
          onClick={() => {
            setContentSelected('bait')
          }}
        >
          cash logs
        </div>
      </div>
    )
  }

  const cashierInfo = () => {
    return (
      <div className="siteDetailSiteInfoBed">
        <div className="dashWindowBed">
          <div className="dashWindowHeader">Cashier info</div>
          <div className="usersListDataRow">
            <div className="usersListDataLabel">Site number</div>
            <div className="usersListDataText">{cashier.siteNumber}</div>
          </div>
          <div className="usersListDataRow">
            <div className="usersListDataLabel">ID</div>
            <div className="usersListDataText">{cashier._id}</div>
          </div>
          <div className="usersListDataRow">
            <div className="usersListDataLabel">Username</div>
            <div className="usersListDataText">{cashier.username}</div>
          </div>
          <div className="usersListDataRow">
            <div className="usersListDataLabel">Created</div>
            <div className="usersListDataText">
              {moment(cashier.created).format('MMMM Do YYYY, h:mm:ss a')}
            </div>
          </div>
        </div>
      </div>
    )
  }

  const transactionHistory = () => {
    if (!cashierTransactionHistory) return null
    let arraySorted = _.orderBy(
      cashierTransactionHistory,
      [(item) => item.created],
      ['desc']
    )
    let array = !showAllCollection ? arrayTenSorted : arraySorted
    return array.map((transaction) => {
      return (
        <div className="transactionHistoryListContainer" key={transaction._id}>
          <div className="transactionHistoryListCell">
            <div className="transactionHistoryListCellLabel">time</div>
            <div className="transactionHistoryListText">
              {moment(transaction.created).format(
                'dddd, MMMM Do YYYY, h:mm:ss a'
              )}
            </div>
          </div>
          <div className="transactionHistoryListCell">
            <div className="transactionHistoryListCellLabel">type</div>
            <div className="transactionHistoryListText">
              {transaction.type === 'addCredit' ? 'add credit' : 'cash out'}
            </div>
          </div>
          <div className="transactionHistoryListCell">
            <div className="transactionHistoryListCellLabel">value</div>
            <div className="transactionHistoryListText">
              {!transaction.value
                ? null
                : transaction.value
                    .toString()
                    .replace(/.(?=(...........)*..$)/g, '$&,')}
            </div>
          </div>
          <div className="transactionHistoryListCell">
            <div className="transactionHistoryListCellLabel">before</div>
            <div className="transactionHistoryListText">
              {transaction.clientOldBalance
                .toString()
                .replace(/.(?=(...........)*..$)/g, '$&,')}
            </div>
          </div>
          <div className="transactionHistoryListCell">
            <div className="transactionHistoryListCellLabel">after</div>
            <div className="transactionHistoryListText">
              {transaction.clientNewBalance
                .toString()
                .replace(/.(?=(...........)*..$)/g, '$&,')}
            </div>
          </div>
        </div>
      )
    })
  }

  const renderContent = () => {
    if (loading) return <Loader />
    return (
      <div className="cashierDetailBed">
        <NavHeader navTo="siteDetail" headerText="Cashier detail" />
        {cashierInfo()}
        {contentSelectorButton()}
        {contentSelected === 'transactions' ? (
          <div className="transactionHistoryListBed">
            <div className="transactionHistoryShowAllButtonBed">
              {!showAllCollection ? (
                <div
                  className="gamePlayShowAllButton"
                  onClick={() => setShowAllCollection(true)}
                >
                  Show all
                </div>
              ) : (
                <div
                  className="gamePlayShowAllButton"
                  onClick={() => setShowAllCollection(false)}
                >
                  Show 10
                </div>
              )}
            </div>
            {transactionHistory()}
          </div>
        ) : (
          <CashLogs />
        )}
      </div>
    )
  }
  return renderContent()
}

export default CashierDetail
