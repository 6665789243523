import React, { useState, useContext, useEffect } from 'react'
import moment from 'moment'

import EditCashier from '../../forms/cashier/EditCashier'
import EditClient from '../../forms/client/EditClient'
import Loader from '../../common/loader/Loader'
import RegisterCashier from '../../forms/cashier/RegisterCashier'
import RegisterClient from '../../forms/client/RegisterClient'
import NavHeader from '../../common/navHeader/NavHeader'
import CashLogs from '../../forms/cashLogs/CashLogs'
import AdjustPercentage from '../../AdjustPercentage/AdjustPercentage'
import SiteBait from './siteBait/SiteBait'
import CommissionAccount from './commissionAccount/CommissionAccount'
import { Context as CreatorContext } from '../../../context/CreatorContext'
import { Context as NavContext } from '../../../context/NavContext'
import { Context as CashLogsContext } from '../../../context/CashLogsContext'
import { Context as BaitContext } from '../../../context/BaitContext'
import './siteDetail.css'

const SiteDetail = () => {
  const [showPreDeleteNoteToId, setShowPreDeleteNoteToId] = useState()
  const [contentSelected, setContentSelected] = useState('cashier')
  const [search, setSearch] = useState('')
  const [clientsFiltered, setClientsFiltered] = useState([])
  const [promoPrizeWheelSwitchOn, setPromoPrizeWheelSwitchOn] = useState(false)

  const {
    state: { loading, error, site, success, cashiers, clients, editFormShow },
    clearSuccess,
    fetchSiteCashier,
    fetchSiteClient,
    setCashierSelected,
    setClientSelected,
    deleteCashier,
    setEditFormProps,
    setEditFormShow,
    fetchSiteTransactionHistory,
  } = useContext(CreatorContext)

  const { setVisibleComponent } = useContext(NavContext)

  const { setTransactionHistoryFor } = useContext(CashLogsContext)

  const { setPromoPrizeWheelActiveDB } = useContext(BaitContext)

  useEffect(() => {
    // setSocketDataFromCreator({
    //   subject: 'promoPrizeWheelOn',
    //   siteNumber: site.siteNumber,
    //   promoPrizeWheelOn: promoPrizeWheelSwitchOn,
    // })
    if (site) {
      setPromoPrizeWheelActiveDB({
        siteNumber: site.siteNumber,
        promoPrizeWheelActive: promoPrizeWheelSwitchOn,
      })
    }
  }, [site, promoPrizeWheelSwitchOn])

  useEffect(() => {
    if (site) {
      fetchSiteCashier({ siteNumber: site.siteNumber })
      fetchSiteClient({ siteNumber: site.siteNumber })
      fetchSiteTransactionHistory({ siteNumber: site.siteNumber })
      setPromoPrizeWheelSwitchOn(site.promoPrizeWheelActive)
    }
  }, [site])

  useEffect(() => {
    if (success) {
      fetchSiteCashier({ siteNumber: site.siteNumber })
      fetchSiteClient({ siteNumber: site.siteNumber })
      clearSuccess()
    }
  }, [success])

  useEffect(() => {
    if (clients) {
      if (clients.length > 0) {
        if (search.length > 0) {
          setClientsFiltered(
            clients.filter((client) => client.username.includes(search))
          )
        } else {
          setClientsFiltered(clients)
        }
      }
    }
  }, [clients, search])

  const siteInfo = () => {
    return (
      <div className="siteDetailSiteInfoBed">
        <div className="dashWindowBed">
          <div className="dashWindowHeader">Site info</div>
          <div className="usersListDataRow">
            <div className="usersListDataLabel">Site number</div>
            <div className="usersListDataText">{site.siteNumber}</div>
          </div>
          <div className="usersListDataRow">
            <div className="usersListDataLabel">ID</div>
            <div className="usersListDataText">{site._id}</div>
          </div>
          <div className="usersListDataRow">
            <div className="usersListDataLabel">Province</div>
            <div className="usersListDataText">{site.province}</div>
          </div>
          <div className="usersListDataRow">
            <div className="usersListDataLabel">Town</div>
            <div className="usersListDataText">{site.town}</div>
          </div>
          <div className="usersListDataRow">
            <div className="usersListDataLabel">Street</div>
            <div className="usersListDataText">{site.street}</div>
          </div>
          <div className="usersListDataRow">
            <div className="usersListDataLabel">Commission percentage</div>
            <div className="usersListDataText">{site.commissionPercentage}</div>
          </div>
          <div className="usersListDataRow">
            <div className="usersListDataLabel">Created</div>
            <div className="usersListDataText">
              {moment(site.created).format('MMMM Do YYYY, h:mm:ss a')}
            </div>
          </div>
        </div>
      </div>
    )
  }

  const perDelteNote = (id) => {
    return (
      <div className="usersListPerDeleteNoteBed">
        <div className="usersListPerDeleteNoteContainer">
          <div className="usersListPerDeleteNote">Confirm delete?</div>
          <div className="listCellButtonBed">
            <div
              className="listCellButton"
              onClick={() => deleteCashier({ id })}
            >
              Yes
            </div>
            <div
              className="listCellButton"
              onClick={() => setShowPreDeleteNoteToId()}
            >
              No
            </div>
          </div>
        </div>
      </div>
    )
  }

  const cashierListButtonSelector = (cashier) => {
    if (cashier._id === showPreDeleteNoteToId) {
      return perDelteNote(cashier._id)
    }
    return (
      <div className="listCellButtonBed">
        <div
          className="listCellButton"
          onClick={() => {
            setEditFormProps(cashier)
            setEditFormShow(true)
          }}
        >
          edit
        </div>
        <div
          className="listCellButton"
          onClick={() => setShowPreDeleteNoteToId(cashier._id)}
        >
          delete
        </div>
        <div
          className="listCellButton"
          onClick={() => {
            setCashierSelected(cashier)
            setVisibleComponent('cashierDetail')
          }}
        >
          expand
        </div>
      </div>
    )
  }

  const clientListButtonSelector = (client) => {
    if (client._id === showPreDeleteNoteToId) {
      return perDelteNote(client._id)
    }
    return (
      <div className="listCellButtonBed">
        <div
          className="listCellButton"
          onClick={() => {
            setEditFormProps(client)
            setEditFormShow(true)
          }}
        >
          edit
        </div>
        <div
          className="listCellButton"
          onClick={() => setShowPreDeleteNoteToId(client._id)}
        >
          delete
        </div>
        <div
          className="listCellButton"
          onClick={() => {
            setClientSelected(client)
            setVisibleComponent('clientDetail')
          }}
        >
          expand
        </div>
      </div>
    )
  }

  const cashierList = () => {
    if (!cashiers) return null
    return cashiers.map((cashier) => {
      return (
        <div className="usersListCell" key={cashier._id}>
          <div className="usersListDataRow">
            <div className="usersListDataLabel">Username</div>
            <div className="usersListDataText">{cashier.username}</div>
          </div>
          <div className="usersListDataRow">
            <div className="usersListDataLabel">Site</div>
            <div className="usersListDataText">{cashier.siteNumber}</div>
          </div>
          <div className="usersListDataRow">
            <div className="usersListDataLabel">Signed in</div>
            <div className="usersListDataText">
              {!cashier.signedIn ? 'False' : 'True'}
            </div>
          </div>
          <div className="usersListButtonSelectorBed">
            {cashierListButtonSelector(cashier)}
          </div>
        </div>
      )
    })
  }

  const renderCashiers = () => {
    if (editFormShow)
      return (
        <div className="siteDetailEditCashierBed">
          <EditCashier />
        </div>
      )
    return (
      <div className="siteDetailCashierBed">
        <div className="siteDetailCashierContainer">
          <div className="dashWindowHeader">Site cashiers</div>
          <div className="listRegisterOrCreateBed">
            <RegisterCashier />
          </div>
          <div className="siteDetailCashierListBed">{cashierList()}</div>
        </div>
      </div>
    )
  }

  const clientList = () => {
    if (!clientsFiltered) return null
    return clientsFiltered.map((client) => {
      return (
        <div className="usersListCell" key={client._id}>
          <div className="usersListDataRow">
            <div className="usersListDataLabel">Username</div>
            <div className="usersListDataText">{client.username}</div>
          </div>
          <div className="usersListDataRow">
            <div className="usersListDataLabel">Site</div>
            <div className="usersListDataText">{client.siteNumber}</div>
          </div>
          <div className="usersListDataRow">
            <div className="usersListDataLabel">Signed in</div>
            <div className="usersListDataText">
              {!client.signedIn ? 'False' : 'True'}
            </div>
          </div>
          <div className="usersListDataRow">
            <div className="usersListDataLabel">Credits</div>
            <div className="usersListDataText">{client.credits}</div>
          </div>
          <div className="usersListButtonSelectorBed">
            {clientListButtonSelector(client)}
          </div>
        </div>
      )
    })
  }

  const searchBar = () => {
    if (clientsFiltered.length < 1 || contentSelected === 'cashier') return null
    return (
      <div className="searchInputBed">
        <input
          className="searchInput"
          type="text"
          placeholder="search client 'username'"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        />
      </div>
    )
  }

  const renderClients = () => {
    if (editFormShow)
      return (
        <div className="siteDetailEditCashierBed">
          <EditClient />
        </div>
      )
    return (
      <div className="siteDetailCashierBed">
        <div className="siteDetailCashierContainer">
          <div className="dashWindowHeader">Site clients</div>
          <div className="listSearchBed">
            {searchBar()}
            <div className="listRegisterOrCreateBed">
              <RegisterClient />
            </div>
            <div className="promoPrizeWheelSwitchBed">
              <div
                className="promoPrizeWheelSwitchContainer"
                onClickCapture={() => {
                  setPromoPrizeWheelSwitchOn(!promoPrizeWheelSwitchOn)
                }}
              >
                <div className="promoPrizeWheelSwitchText">
                  promo prize wheel: {promoPrizeWheelSwitchOn ? 'ON' : 'OFF'}
                </div>
              </div>
            </div>
          </div>
          {error !== 'client username, already in use...' ? null : (
            <div className="siteDetailErrorSpacer" />
          )}
          <div className="siteDetailCashierListBed">{clientList()}</div>
        </div>
      </div>
    )
  }

  const contentSelectorButton = () => {
    return (
      <div className="siteDetailSelectorButtonBed">
        <div
          className={
            contentSelected === 'cashier'
              ? 'siteDetailSelectorButtonActive'
              : 'siteDetailSelectorButton'
          }
          onClick={() => setContentSelected('cashier')}
        >
          cashier
        </div>
        <div
          className={
            contentSelected === 'client'
              ? 'siteDetailSelectorButtonActive'
              : 'siteDetailSelectorButton'
          }
          onClick={() => setContentSelected('client')}
        >
          client
        </div>
        <div
          className={
            contentSelected === 'adjust %'
              ? 'siteDetailSelectorButtonActive'
              : 'siteDetailSelectorButton'
          }
          onClick={() => setContentSelected('adjust %')}
        >
          adjust %
        </div>
        <div
          className={
            contentSelected === 'cash logs'
              ? 'siteDetailSelectorButtonActive'
              : 'siteDetailSelectorButton'
          }
          onClick={() => {
            setTransactionHistoryFor('site')
            setContentSelected('cash logs')
          }}
        >
          cash logs
        </div>
        <div
          className={
            contentSelected === 'bait'
              ? 'siteDetailSelectorButtonActive'
              : 'siteDetailSelectorButton'
          }
          onClick={() => {
            setContentSelected('bait')
          }}
        >
          bait
        </div>
        <div
          className={
            contentSelected === 'commissionAccount'
              ? 'siteDetailSelectorButtonActive'
              : 'siteDetailSelectorButton'
          }
          onClick={() => setContentSelected('commissionAccount')}
        >
          accounts
        </div>
      </div>
    )
  }

  const contentSelecter = () => {
    switch (contentSelected) {
      case 'cashier':
        return renderCashiers()
      case 'client':
        return renderClients()
      case 'cash logs':
        return <CashLogs />
      case 'adjust %':
        return <AdjustPercentage />
      case 'bait':
        return <SiteBait />
      case 'commissionAccount':
        return <CommissionAccount />
      default:
        break
    }
  }

  const renderContent = () => {
    if (loading) return <Loader />
    return (
      <>
        <NavHeader navTo="operatorSiteList" headerText="Site detail" />
        {siteInfo()}
        {contentSelectorButton()}
        {contentSelecter()}
      </>
    )
  }

  return renderContent()
}

export default SiteDetail
