import React, { useContext, useEffect } from 'react'

import Dashboard from './screens/dashboard/Dashboard'
import SiteDetail from './screens/siteDetailt/SiteDetail'
import ClientDetail from './screens/clientDetail/ClientDetail'
import OperatorList from './screens/operatorList/OperatorList'
import OperatorDetail from './screens/operatorDetail/OperatorDetail'
import OperatorSiteList from './screens/operatorSiteList/OperatorSiteList'
import CashierDetail from './screens/cashierDetail/CashierDetail'
import socket from '../api/socketIo'
import Loader from './common/loader/Loader'
import Login from './auth/login/Login'
import Socket from './common/socket/Socket'
import { Context as AuthContext } from '../context/AuthContext'
import { Context as UserContext } from '../context/UserContext'
import { Context as SocketContext } from '../context/SocketContext'
import { Context as NavContext } from '../context/NavContext'

const AppScreens = () => {
  const {
    state: { loading, success },
  } = useContext(AuthContext)

  const {
    state: { user },
  } = useContext(UserContext)

  const {
    state: { socketChanel },
    setSocketChanel,
  } = useContext(SocketContext)

  const {
    state: { visibleComponent },
    setVisibleComponent,
  } = useContext(NavContext)

  useEffect(() => {
    localStorage.getItem('token')
  }, [])

  useEffect(() => {
    if (localStorage.getItem('token')) {
      setVisibleComponent('dashboard')
    } else {
      setVisibleComponent('login')
    }
  }, [localStorage.getItem('token')])

  useEffect(() => {
    if (user) {
      const { creator, username } = user
      if (!creator) {
        localStorage.removeItem('token')
        setVisibleComponent('login')
      }
      setSocketChanel(username)
    }
  }, [user])

  useEffect(() => {
    if (socketChanel !== '') {
      socket.emit('join_chanel', socketChanel)
    }
  }, [socketChanel])

  useEffect(() => {
    if (success === 'signed out successfully') {
      localStorage.removeItem('token')
      setVisibleComponent('login')
    }
  }, [success])

  const componentSelector = () => {
    switch (visibleComponent) {
      case 'dashboard':
        return <Dashboard />
      case 'siteDetail':
        return <SiteDetail />
      case 'clientDetail':
        return <ClientDetail />
      case 'operatorList':
        return <OperatorList />
      case 'operatorDetail':
        return <OperatorDetail />
      case 'operatorSiteList':
        return <OperatorSiteList />
      case 'cashierDetail':
        return <CashierDetail />
      default:
        return <Login />
    }
  }

  const renderContent = () => {
    if (loading) return <Loader />
    return (
      <>
        <Socket />
        {componentSelector()}
      </>
    )
  }

  return renderContent()
}

export default AppScreens
