import { useContext, useEffect, useState } from 'react'

import socket from '../../../api/socketIo'
import { Context as SocketContext } from '../../../context/SocketContext'
import { Context as BaitContext } from '../../../context/BaitContext'

const Socket = () => {
  const [socketSetupDone, setSocketSetupDone] = useState(false)

  const {
    state: { socketChanel, socketDataFromCreator, socketDataFromClient },
    setSocketChanel,
    setSocketDataFromCreator,
    setSocketDataFromClient,
  } = useContext(SocketContext)

  const {
    state: { baitTiriggerData },
    setBaitTiriggerData,
  } = useContext(BaitContext)

  useEffect(() => {
    if (socketChanel) {
      socket.emit('join_chanel', socketChanel)
      setSocketSetupDone(true)
    }
  }, [socketChanel])

  useEffect(() => {
    if (socketDataFromCreator) {
      socket.emit('send_data', { socketDataFromCreator, socketChanel })
      setSocketDataFromCreator(null)
    }
  }, [socketDataFromCreator])

  useEffect(() => {
    if (socketSetupDone) {
      socket.on('receive_data', (data) => {
        console.log(`data:`, data)
        if (data) {
          if (data.socketDataFromClient) {
            setSocketDataFromClient(data.socketDataFromClient)
          }
        }
      })
    }
  }, [socketSetupDone, socket])

  useEffect(() => {
    if (socketDataFromClient) {
      const { subject } = socketDataFromClient
      if (subject === 'baitTiriggerData') {
        setBaitTiriggerData([
          ...baitTiriggerData,
          socketDataFromClient.baitTiriggerData,
        ])
        setSocketDataFromClient(null)
      }
    }
  }, [socketDataFromClient])

  return null
}

export default Socket
