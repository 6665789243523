import React, { useState, useContext, useEffect } from 'react'

import RegisterOperator from '../../forms/operator/RegisterOperator'
import EditOperator from '../../forms/operator/EditOperator'
import NavHeader from '../../common/navHeader/NavHeader'
import Loader from '../../common/loader/Loader'
import { Context as CreatorContext } from '../../../context/CreatorContext'
import { Context as NavContext } from '../../../context/NavContext'

const OperatorsList = () => {
  const [search, setSearch] = useState('')
  const [operatorsFiltered, setOperatorsFiltered] = useState([])
  const [showPreDeleteNoteToId, setShowPreDeleteNoteToId] = useState()

  const {
    state: { loading, success, operators, editFormShow },
    fetchOperators,
    deleteUser,
    clearSuccess,
    setEditFormShow,
    setEditFormProps,
    setOperatorSelected,
  } = useContext(CreatorContext)

  const { setVisibleComponent } = useContext(NavContext)

  useEffect(() => {
    fetchOperators()
  }, [])

  useEffect(() => {
    if (success) {
      if (success.length > 0) {
        fetchOperators()
        clearSuccess()
      }
    }
  }, [success])

  useEffect(() => {
    if (operators) {
      if (operators.length > 0) {
        if (search.length > 0) {
          setOperatorsFiltered(
            operators.filter((operator) => operator.username.includes(search))
          )
        } else {
          setOperatorsFiltered(operators)
        }
      }
    }
  }, [operators, search])

  const perDelteNote = (id) => {
    return (
      <div className="usersListPerDeleteNoteBed">
        <div className="usersListPerDeleteNoteContainer">
          <div className="usersListPerDeleteNote">Confirm delete?</div>
          <div className="listCellButtonBed">
            <div className="listCellButton" onClick={() => deleteUser({ id })}>
              Yes
            </div>
            <div
              className="listCellButton"
              onClick={() => setShowPreDeleteNoteToId()}
            >
              No
            </div>
          </div>
        </div>
      </div>
    )
  }

  const searchBar = () => {
    return (
      <div className="searchInputBed">
        <input
          className="searchInput"
          type="text"
          placeholder="search operator 'username'"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        />
      </div>
    )
  }

  const listButtonSelector = (operator) => {
    if (operator._id === showPreDeleteNoteToId) {
      return perDelteNote(operator._id)
    }
    return (
      <div className="listCellButtonBed">
        <div
          className="listCellButton"
          onClick={() => {
            setEditFormProps(operator)
            setEditFormShow(true)
          }}
        >
          edit
        </div>
        <div
          className="listCellButton"
          onClick={() => setShowPreDeleteNoteToId(operator._id)}
        >
          delete
        </div>
        <div
          className="listCellButton"
          onClick={() => {
            setOperatorSelected(operator)
            setVisibleComponent('operatorDetail')
          }}
        >
          expand
        </div>
      </div>
    )
  }

  const list = () => {
    if (!operatorsFiltered || operatorsFiltered.length < 1) return null
    return operatorsFiltered.map((operator) => {
      return (
        <div className="usersListCell" key={operator._id}>
          <div className="usersListDataRow">
            <div className="usersListDataLabel">Username</div>
            <div className="usersListDataText">{operator.username}</div>
          </div>
          <div className="usersListDataRow">
            <div className="usersListDataLabel">Signed in</div>
            <div className="usersListDataText">
              {!operator.signedIn ? 'False' : 'True'}
            </div>
          </div>
          <div className="usersListDataRow">
            <div className="usersListDataLabel">Phone 1</div>
            <div className="usersListDataText">{operator.phone1}</div>
          </div>
          <div className="usersListDataRow">
            <div className="usersListDataLabel">Phone 2</div>
            <div className="usersListDataText">{operator.phone2}</div>
          </div>
          <div className="usersListDataRow">
            <div className="usersListDataLabel">Phone 3</div>
            <div className="usersListDataText">{operator.phone3}</div>
          </div>
          <div className="usersListDataRow">
            <div className="usersListDataLabel">Email 1</div>
            <div className="usersListDataText">{operator.email1}</div>
          </div>
          <div className="usersListDataRow">
            <div className="usersListDataLabel">Email 2</div>
            <div className="usersListDataText">{operator.email2}</div>
          </div>
          <div className="usersListDataRow">
            <div className="usersListDataLabel">Email 3</div>
            <div className="usersListDataText">{operator.email3}</div>
          </div>
          <div className="usersListButtonSelectorBed">
            {listButtonSelector(operator)}
          </div>
        </div>
      )
    })
  }

  const renderContent = () => {
    if (loading) return <Loader />
    if (editFormShow) {
      return (
        <div className="listEditFormBed">
          <EditOperator />
        </div>
      )
    }
    return (
      <>
        <NavHeader navTo="dashboard" headerText="Operators list" />
        <div className="listSearchBed">
          {searchBar()}
          <div className="listRegisterOrCreateBed">
            <RegisterOperator />
          </div>
        </div>
        <div className="listBed">
          <div className="listContainer">{list()}</div>
        </div>
      </>
    )
  }

  return renderContent()
}

export default OperatorsList
