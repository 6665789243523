import React, { useContext } from 'react'

import { Context as NavContext } from '../../../context/NavContext'

const OperatorDashWindow = () => {
  const { setVisibleComponent } = useContext(NavContext)

  const navToOperatorListScreen = () => {
    return (
      <div className="dashboardNavLinkButtonBed">
        <div
          className="dashboardNavLinkButton"
          onClick={() => setVisibleComponent('operatorList')}
        >
          view operators
        </div>
      </div>
    )
  }

  const renderContent = () => {
    return (
      <div className="dashWindowBed">
        <div className="dashWindowHeader">Operator</div>
        {navToOperatorListScreen()}
      </div>
    )
  }

  return renderContent()
}

export default OperatorDashWindow
