import ngrokApi from '../api/ngrokApi'
import createDataContext from './createDataContext'

// Reducer
const authReducer = (state, action) => {
  switch (action.type) {
    case 'LOADING':
      return { ...state, loading: true }
    case 'STOP_LOADING':
      return { ...state, loading: false }
    case 'SET_PANIC':
      return { ...state, panic: action.payload }
    case 'ADD_ERROR':
      return { ...state, error: action.payload, loading: false }
    case 'CLEAR_ERROR':
      return { ...state, error: action.payload, loading: false }
    case 'ADD_SUCCESS':
      return { ...state, success: action.payload, loading: false }
    case 'CLEAR_SUCCESS':
      return { ...state, success: action.payload, loading: false }
    default:
      return state
  }
}

// Actions
const login =
  (dispatch) =>
  async ({ email, password }) => {
    dispatch({ type: 'LOADING' })
    try {
      const response1 = await ngrokApi.post('/auth/user/login', {
        email,
        password,
      })
      if (response1.data.error) {
        dispatch({ type: 'ADD_ERROR', payload: response1.data.error })
        return
      } else {
        const response2 = await ngrokApi.patch(
          'auth/user/update-signed-in-status',
          { email }
        )
        if (response2.data.error) {
          dispatch({ type: 'ADD_ERROR', payload: response2.data.error })
          return
        }
        if (response2.data.success) {
          localStorage.setItem('token', response1.data.token)
          dispatch({ type: 'SIGN_IN', payload: response1.data.token })
          dispatch({ type: 'STOP_LOADING' })
        }
      }
    } catch (err) {
      dispatch({
        type: 'ADD_ERROR',
        payload: err.response.data,
      })
    }
  }

const signOut = (dispatch) => async () => {
  dispatch({ type: 'LOADING' })
  const response = await ngrokApi.patch('/auth/user/sign-out')
  if (response.data.error) {
    dispatch({ type: 'ADD_ERROR', payload: response.data.error })
    return
  }
  if (response.data.success) {
    dispatch({ type: 'ADD_SUCCESS', payload: response.data.success })
    return
  }
}

const setPanic = (dispatch) => (value) => {
  dispatch({ type: 'SET_PANIC', payload: value })
}

const clearError = (dispatch) => async () => {
  dispatch({ type: 'CLEAR_ERROR', payload: null })
}

const clearSuccess = (dispatch) => async () => {
  dispatch({ type: 'CLEAR_SUCCESS', payload: null })
}

export const { Provider, Context } = createDataContext(
  authReducer,
  {
    clearError,
    clearSuccess,
    login,
    signOut,
    setPanic,
  },
  { loading: null, error: null, success: null, panic: false }
)
