import React from 'react'

import './loader.css'

const Loader = () => {
  return (
    <div className="loaderSmallBed">
      <div className="ui small active inverted inline loader" />
    </div>
  )
}

export default Loader
