import React, { useContext, useEffect, useState } from 'react'
import moment from 'moment'

import { Context as CashLogsContext } from '../../../../context/CashLogsContext'
import { Context as CreatorContext } from '../../../../context/CreatorContext'
import './commissionAccount.css'

const CommissionAccountList = () => {
  const [showConfirm, setShowConfirm] = useState(false)

  const {
    state: { loading, commissionAccount },
    fetchCommissionAccount,
    updatePaidCommissionAccount,
  } = useContext(CashLogsContext)

  const {
    state: { siteTransactionHistory, site, operator },
  } = useContext(CreatorContext)

  useEffect(() => {
    fetchCommissionAccount({ siteNumber: site.siteNumber })
  }, [])

  const renderMarkAsPaidButton = (accountId) => {
    if (!showConfirm) {
      return (
        <div
          className="commissionPaidButton"
          onClick={() => setShowConfirm(true)}
        >
          Mark as paid
        </div>
      )
    }
    return (
      <>
        <div
          className="commissionPaidButton"
          onClick={() => {
            updatePaidCommissionAccount({
              accountId: accountId,
              siteNumber: site.siteNumber,
            })
          }}
        >
          Confirm
        </div>
        <div
          className="commissionPaidButton"
          onClick={() => setShowConfirm(false)}
        >
          Cancel
        </div>
      </>
    )
  }

  const renderList = () => {
    if (!commissionAccount || commissionAccount.length < 1) return null
    return commissionAccount.map((com) => {
      return (
        <div className="commissionAccountListItemContainer" key={com._id}>
          <div className="commissionAccountListItemRow">
            <div className="commissionAccountListItemLabel">Date</div>
            <div className="commissionAccountListItemText">
              {moment(com.created).format('MMMM Do YYYY')}
            </div>
          </div>
          <div className="commissionAccountListItemRow">
            <div className="commissionAccountListItemLabel">Value</div>
            <div className="commissionAccountListItemText">
              {com.commissionValue}
            </div>
          </div>
          <div className="commissionAccountListItemRow">
            <div className="commissionAccountListItemLabel">Percentage</div>
            <div className="commissionAccountListItemText">
              {com.commissionPercentage}
            </div>
          </div>
          <div className="commissionAccountListItemRow">
            <div className="commissionAccountListItemLabel">In</div>
            <div className="commissionAccountListItemText">{com.valueIn}</div>
          </div>
          <div className="commissionAccountListItemRow">
            <div className="commissionAccountListItemLabel">Out</div>
            <div className="commissionAccountListItemText">{com.valueOut}</div>
          </div>
          <div className="commissionAccountListItemRow">
            <div className="commissionAccountListItemLabel">Profit</div>
            <div className="commissionAccountListItemText">{com.profit}</div>
          </div>
          <div className="commissionAccountListItemRow">
            <div className="commissionAccountListItemLabel">Billed</div>
            <div className="commissionAccountListItemText">
              {com.commissionBilled ? 'True' : 'False'}
            </div>
          </div>
          <div className="commissionAccountListItemRow">
            <div className="commissionAccountListItemLabel">Paid</div>
            <div className="commissionAccountListItemText">
              {com.commissionPaid ? 'True' : 'False'}
            </div>
          </div>
          <div className="commissionPaidButtonBed"></div>
          {com.commissionPaid ? (
            <div className="commissionPaidMark">
              PAID
              <div className="commissionPaidMarkDate">
                {moment(com.commissionPaidDate).format('MMMM Do YYYY')}
              </div>
            </div>
          ) : (
            renderMarkAsPaidButton(com._id)
          )}
        </div>
      )
    })
  }

  const renderContent = () => {
    if (loading) return <div className="siteBaitLoader">Loading...</div>
    return <div className="commissionAccountListItemBed">{renderList()}</div>
  }

  return renderContent()
}

export default CommissionAccountList
