import React, { useContext, useState, useEffect } from 'react'
import { ImCheckboxUnchecked, ImCheckboxChecked } from 'react-icons/im'

import { Context as CreatorContext } from '../../context/CreatorContext'
import './adjustPercentage.css'

const AdjustPercentage = () => {
  const [adjustAllClients, setAdjustAllClients] = useState(false)
  const [percentage, setPercentage] = useState('0')

  const {
    state: { site },
    editSite,
  } = useContext(CreatorContext)

  useEffect(() => {
    if (!adjustAllClients) {
      setPercentage('0')
    }
  }, [adjustAllClients])

  const renderContent = () => {
    return (
      <div className="siteDetailCashierBed">
        <div className="siteDetailCashierContainer">
          <div className="dashWindowHeader">Adjust %</div>
          <div className="adjustPercentageRow">
            <div className="adjustPercentageText">Ajust all clients</div>
            {adjustAllClients === true ? (
              <ImCheckboxChecked
                className="adjustPercentageText"
                onClick={() => setAdjustAllClients(!adjustAllClients)}
              />
            ) : (
              <ImCheckboxUnchecked
                className="adjustPercentageText"
                onClick={() => setAdjustAllClients(!adjustAllClients)}
              />
            )}
          </div>
          {!adjustAllClients ? null : (
            <>
              <div className="adjustPercentageRow">
                <div className="adjustPercentageText">1</div>
                {percentage === '1' ? (
                  <ImCheckboxChecked className="adjustPercentageText" />
                ) : (
                  <ImCheckboxUnchecked
                    className="adjustPercentageText"
                    onClick={() => setPercentage('1')}
                  />
                )}
              </div>
              <div className="adjustPercentageRow">
                <div className="adjustPercentageText">2</div>
                {percentage === '2' ? (
                  <ImCheckboxChecked className="adjustPercentageText" />
                ) : (
                  <ImCheckboxUnchecked
                    className="adjustPercentageText"
                    onClick={() => setPercentage('2')}
                  />
                )}
              </div>
              <div className="adjustPercentageRow">
                <div className="adjustPercentageText">3</div>
                {percentage === '3' ? (
                  <ImCheckboxChecked className="adjustPercentageText" />
                ) : (
                  <ImCheckboxUnchecked
                    className="adjustPercentageText"
                    onClick={() => setPercentage('3')}
                  />
                )}
              </div>
              <div className="adjustPercentageRow">
                <div className="adjustPercentageText">Random</div>
                {percentage === 'random' ? (
                  <ImCheckboxChecked className="adjustPercentageText" />
                ) : (
                  <ImCheckboxUnchecked
                    className="adjustPercentageText"
                    onClick={() => setPercentage('random')}
                  />
                )}
              </div>
              <div className="listCellButtonBed">
                <div
                  className="listCellButton"
                  onClick={() =>
                    editSite({ id: site._id, percentage: percentage })
                  }
                >
                  submit
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    )
  }

  return renderContent()
}

export default AdjustPercentage
