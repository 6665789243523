import createDataContext from './createDataContext'
import ngrokApi from '../api/ngrokApi'

// Reducer
const BaitContext = (state, action) => {
  switch (action.type) {
    case 'LOADING':
      return { ...state, loading: true }
    case 'ADD_ERROR':
      return { ...state, error: action.payload, loading: false }
    case 'CLEAR_ERROR':
      return { ...state, error: action.payload }
    case 'SET_PROMO_PRIZE_WHEEL_ACTIVE_DB':
      return { ...state, promoPrizeWheelActiveDB: action.payload }
    case 'CREATE_SITE_BAIT':
      return { ...state, bait: action.payload, loading: false }
    case 'FETCH_SITE_BAIT_LIST':
      return { ...state, bait: action.payload, loading: false }
    case 'SET_BAIT_TRIGGER_DATA':
      return { ...state, baitTiriggerData: action.payload }
    default:
      return state
  }
}

// Actions
const clearError = (dispatch) => () => {
  dispatch({ type: 'CLEAR_ERROR', payload: null })
}

const setPromoPrizeWheelActiveDB = () => async (data) => {
  await ngrokApi.patch('/creator/set-promo-prize-wheel-active/', data)
}

const createSiteBait = (dispatch) => async (data) => {
  dispatch({ type: 'LOADING' })
  try {
    const response = await ngrokApi.post('/bait/create-site-bait/', data)
    if (response.data.error) {
      dispatch({ type: 'ADD_ERROR', payload: response.data.error })
      return
    }
    dispatch({ type: 'CREATE_SITE_BAIT', payload: response.data })
    return
  } catch (error) {
    dispatch({
      type: 'ADD_ERROR',
      payload: error,
    })
  }
}

const fetchSiteBaitList = (dispatch) => async (data) => {
  dispatch({ type: 'LOADING' })
  try {
    const response = await ngrokApi.post('/bait/fetch-site-bait-list/', data)
    dispatch({ type: 'FETCH_SITE_BAIT_LIST', payload: response.data })
    return
  } catch (error) {
    dispatch({
      type: 'ADD_ERROR',
      payload: error,
    })
  }
}

const setBaitTiriggerData = (dispatch) => (data) => {
  dispatch({ type: 'SET_BAIT_TRIGGER_DATA', payload: data })
}

export const { Context, Provider } = createDataContext(
  BaitContext,
  {
    clearError,
    setPromoPrizeWheelActiveDB,
    createSiteBait,
    fetchSiteBaitList,
    setBaitTiriggerData,
  },
  // Initial state
  {
    loading: false,
    error: null,
    promoPrizeWheelActiveDB: null,
    bait: null,
    baitTiriggerData: [],
  }
)
