import React from 'react'
import { Provider as AuthProvider } from '../context/AuthContext'
import { Provider as CreatorProvider } from '../context/CreatorContext'
import { Provider as UserProvider } from '../context/UserContext'
import { Provider as NavProvider } from '../context/NavContext'
import { Provider as SocketProvider } from '../context/SocketContext'
import { Provider as ModalProvider } from '../context/ModalContext'
import { Provider as CashLogsProvider } from '../context/CashLogsContext'
import { Provider as BaitProvider } from '../context/BaitContext'

import AppScreens from './AppScreens'
import './app.css'

const App = () => {
  return (
    <div className="appBed">
      <AuthProvider>
        <CreatorProvider>
          <UserProvider>
            <NavProvider>
              <SocketProvider>
                <ModalProvider>
                  <CashLogsProvider>
                    <BaitProvider>
                      <AppScreens />
                    </BaitProvider>
                  </CashLogsProvider>
                </ModalProvider>
              </SocketProvider>
            </NavProvider>
          </UserProvider>
        </CreatorProvider>
      </AuthProvider>
    </div>
  )
}

export default App
