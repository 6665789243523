import React, { useContext } from 'react'
import { AiOutlineArrowLeft } from 'react-icons/ai'

import { Context as NavContext } from '../../../context/NavContext'
import './navHeader.css'

const NavHeader = ({ navTo, headerText }) => {
  const {
    state: { visibleComponent },
    setVisibleComponent,
  } = useContext(NavContext)

  const renderContent = () => {
    return (
      <div className="navBed">
        {visibleComponent === 'dashboard' ? null : (
          <div className="navButtonsBed">
            <div
              className="navBackArrowButtonBed"
              onClick={() => setVisibleComponent(navTo)}
            >
              <AiOutlineArrowLeft className="navBackArrowButton" />
              <div className="navButtonText">back</div>
            </div>
            <div className="navButtonDivider">|</div>
            <div
              className="navButtonText"
              onClick={() => setVisibleComponent('dashboard')}
            >
              home
            </div>
          </div>
        )}
        <div className="navHeaderBed">
          <div className="navHeader">{headerText}</div>
        </div>
        <div
          className="navSignoutButton"
          onClick={() => {
            localStorage.removeItem('token')
            setVisibleComponent('login')
          }}
        >
          logout
        </div>
      </div>
    )
  }

  return renderContent()
}

export default NavHeader
