import React, { useContext, useState } from 'react'
import { AiOutlineArrowLeft } from 'react-icons/ai'
import { ImCheckboxUnchecked, ImCheckboxChecked } from 'react-icons/im'

import LoaderSmall from '../../common/loader/LoaderSmall'
import { Context as CreatorContext } from '../../../context/CreatorContext'

const CreateSite = () => {
  const [showForm, setShowForm] = useState(false)
  const [type, setType] = useState('')
  const [siteNumber, setSiteNumber] = useState('')
  const [province, setProvince] = useState('')
  const [town, setTown] = useState('')
  const [street, setStreet] = useState('')
  const [commissionPercentage, setCommissionPercentage] = useState(0)

  const {
    state: { loading, error, success, operator },
    createSite,
    clearError,
  } = useContext(CreatorContext)

  const submit = () => {
    createSite({
      operatorId: operator._id,
      type,
      siteNumber,
      province,
      town,
      street,
      commissionPercentage,
    })
  }

  const siteTypeSelector = () => {
    return (
      <div className="formCheckBoxBed">
        <div className="formCheckBoxCell">
          <div className="formCheckBoxText">Store</div>
          {type === 'store' ? (
            <ImCheckboxChecked />
          ) : (
            <ImCheckboxUnchecked onClick={() => setType('store')} />
          )}
        </div>
        <div className="formCheckBoxCell">
          <div className="formCheckBoxText">Online</div>
          {type === 'online' ? (
            <ImCheckboxChecked />
          ) : (
            <ImCheckboxUnchecked onClick={() => setType('online')} />
          )}
        </div>
      </div>
    )
  }

  const renderError = () => {
    if (!error) return null
    return (
      <div className="formErrorBed">
        <div className="formErrorText">{error}</div>
        <div className="formErrorButtonBed">
          <div className="formErrorButton" onClick={() => clearError()}>
            ok
          </div>
        </div>
      </div>
    )
  }

  const button = () => {
    if (error || success) return null
    if (showForm) {
      return (
        <div className="formCancelButton" onClick={() => setShowForm(false)}>
          <AiOutlineArrowLeft className="formCancelButtonIcon" />
          <div className="formCancelButtonTextBed">
            <div className="formCancelButtonText">cancel</div>
          </div>
        </div>
      )
    }
    return (
      <div className="formOpenButtonBed">
        <div className="formOpenButton" onClick={() => setShowForm(true)}>
          create site
        </div>
      </div>
    )
  }

  const form = () => {
    if (loading) return <LoaderSmall />
    if (!showForm || error || success) return null
    return (
      <div>
        <>
          <div className="formInputHeader">Site type</div>
          {siteTypeSelector()}
        </>
        <>
          <div className="formInputHeader">Site number</div>
          <input
            className="formInput"
            type="text"
            placeholder="sight number"
            value={siteNumber}
            onChange={(e) => setSiteNumber(e.target.value)}
          />
        </>
        <>
          <div className="formInputHeader">Province</div>
          <input
            className="formInput"
            type="text"
            placeholder="province"
            value={province}
            onChange={(e) => setProvince(e.target.value)}
          />
        </>
        <>
          <div className="formInputHeader">Town</div>
          <input
            className="formInput"
            type="text"
            placeholder="town"
            value={town}
            onChange={(e) => setTown(e.target.value)}
          />
        </>
        <>
          <div className="formInputHeader">Street</div>
          <input
            className="formInput"
            type="text"
            placeholder="street"
            value={street}
            onChange={(e) => setStreet(e.target.value)}
          />
        </>
        <>
          <div className="formInputHeader">Commission percentage</div>
          <input
            className="formInput"
            type="text"
            placeholder="commission percentage"
            value={commissionPercentage}
            onChange={(e) => setCommissionPercentage(e.target.value)}
          />
        </>
        <div className="formSubmitButtonBed">
          <div className="formSubmitButton" onClick={() => submit()}>
            submit
          </div>
        </div>
      </div>
    )
  }

  const renderContent = () => {
    return (
      <div className={!showForm ? 'formBedNoBorder' : 'formBed'}>
        <>{renderError()}</>
        <>{button()}</>
        <>{form()}</>
      </div>
    )
  }

  return renderContent()
}

export default CreateSite
